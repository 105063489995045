import request from '@/utils/request'
export function loadBanner(parameter) {
    return request({
        url: '/api/index/banner',
        method: 'post',
        data: parameter
    })
}
// 获取首页开课提醒
export function classes_remind(parameter) {
    return request({
        url: '/api/user/classes_remind',
        method: 'post',
        data: parameter
    })
}
// 公共发送验证码方法
export function sendMessage(parameter) {
    return request({
        url: '/api/common/sendMessage',
        method: 'post',
        data: parameter
    })
}
export function mobileLogin(parameter) {
    return request({
        url: '/api/user/login/mobileLogin',
        method: 'post',
        data: parameter
    })
}
export function login(parameter) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data: parameter
    })
}
// 获取拼团列表
export function PinTuanList(parameter) {
    return request({
        url: '/api/pintuanGroup/list',
        method: 'post',
        data: parameter
    })
}
/**
 * 更新视频观看进度
 * @param parameter
 */
 export function updateVideoProgress(parameter) {
    return request({
        url: '/api/common/updateVideoProgress',
        method: 'post',
        data: parameter
    })
}
// 获取本人收货地址列表
export function addressList(parameter) {
    return request({
        url: '/api/shipping_address/getlist',
        method: 'post',
        data: parameter
    })
}

// 下单时获取当前可用优惠券列表
export function able(parameter) {
    return request({
        url: '/api/coupon/able',
        method: 'post',
        data: parameter
    })
}

// H5支付
export function wapPay(parameter) {
    return request({
        url: '/api/pay/wap',
        method: 'post',
        data: parameter
    })
}
// app支付
export function appPay(parameter) {
    return request({
        url: '/api/pay/wxWap',
        method: 'post',
        data: parameter
    })
}
// 商品下单
export function ordinary(parameter) {
    return request({
        url: 'api/buy/ordinarySpecial',
        method: 'post',
        data: parameter
    })
}
/**
 * 秒杀下单
 * @param parameter
 */
export function seckill(parameter) {
    return request({
        url: '/api/buy/seckillSpecial',
        method: 'post',
        data: parameter
    })
}
/**
 * 拼团下单
 * @param parameter
 */
export function pintuan(parameter) {
    return request({
        url: '/api/buy/pintuanSpecial',
        method: 'post',
        data: parameter
    })
}
// 获取某团组成员
export function pintuanUser(parameter) {
    return request({
        url: '/api/pintuanUser/list',
        method: 'post',
        data: parameter
    })
}
// 获取订单详情
export function ordrDetail(parameter) {
    return request({
        url: '/api/user/order/detail',
        method: 'post',
        data: parameter
    })
}
// 三级联动
export function getLocation(parameter) {
    return request({
        url: '/api/common/get_location',
        method: 'post',
        data: parameter
    })
}
// 添加收货地址
export function addLocation(parameter) {
    return request({
        url: '/api/shipping_address/add',
        method: 'post',
        data: parameter
    })
}
// 评论点赞/取消赞
export function commentLike(parameter) {
    return request({
        url: '/api/comment/like',
        method: 'post',
        data: parameter
    })
}
// 分享注册
export function shareRegister(parameter) {
    return request({
        url: '/api/user/login/shareRegister',
        method: 'post',
        data: parameter
    })
}
// 获取分享产品id
export function getShare(parameter) {
    return request({
        url: '/api/user/share/getShare',
        method: 'post',
        data: parameter
    })
}
// 分享买课
export function user_share(parameter) {
    return request({
        url: '/api/user/share/user_share',
        method: 'post',
        data: parameter
    })
}
export function socialiteWeiXinLogin(parameter) {
    return request({
        url: '/api/user/login/socialiteWeiXin/weixin',
        method: 'post',
        data: parameter
    })
}
export function socialiteCallBackMobile(parameter) {
    return request({
        url: '/api/user/login/socialiteWeiXinLogin',
        method: 'post',
        data: parameter
    })
}

// 获取用户信息
export function getUserInfo(parameter) {
    return request({
        url: '/api/user/getUserInfo',
        method: 'post',
        data: parameter
    })
}

// 资讯详情
export function information(parameter) {
    return request({
        url: '/api/information/detail',
        method: 'post',
        data: parameter
    })
}
// 资讯详情
export function getSignPackage(parameter) {
    return request({
        url: '/api/index/getSignPackage',
        method: 'post',
        data: parameter
    })
}

// 获取课程文件地址
export function courseDownload(parameter) {
    return request({
        url: '/api/school/handout/download',
        method: 'post',
        data: parameter
    })
}
// 获取商城文件地址
export function waresDownload(parameter) {
    return request({
        url: '/api/wares/download',
        method: 'post',
        data: parameter
    })
}
