<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <a @click="goIndex"
        class="mui-action-back mui-icon mui-icon-closeempty"
        style="font-size: 46px"
      ></a>
    </header>
    <div class="mui-content">
         <div class="warp login" v-if="weixinType ==1">
        <h3 class="mui-text-center">
          <img class="login-logo" src="@/assets/img/login-img1.jpg" />
        </h3>
        
        <ul>
          <li>
            <input
              placeholder="请输入手机号码"
              v-model="form.phone"
              type="text"
              id=""
              value=""
            />
          </li>
          <li style="position: relative">
            <input
              placeholder="请输入验证码"
              v-model="form.code"
              type="text"
              id=""
              value=""
            />
            <SrButton
              :disabled="sms_time !== 0"
              @click="sendMessage"
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              style="position: absolute; right: 0; bottom: 26px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
          </li>
        </ul>
        <SrButton style="width: 100%" @click="login" :loading="login_loading">
          登录
        </SrButton>
      </div>
      <div class="warp login" v-else>
        <h3 class="mui-text-center">
          <img class="login-logo" src="@/assets/img/login-img1.jpg" />
        </h3>
        <ul>
          <li>
            <input
              placeholder="请输入手机号码"
              v-model="mobile"
              type="text"
              id=""
              value=""
            />
          </li>
          <li style="position: relative">
            <input
              placeholder="请输入验证码"
              v-model="code"
              type="text"
              id=""
              value=""
            />
            <SrButton
              :disabled="sms_time !== 0"
              @click="sendMessage2"
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              style="position: absolute; right: 0; bottom: 26px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
          </li>
        </ul>
        <SrButton style="width: 100%" @click="weixinLogin" :loading="login_loading">
          绑定手机号
        </SrButton>
        
      </div>
      <p class="mt05">
           <div style="display:inline-block;margin-left:15px" v-if="weixinType ==1" @click="goPassword">密码登录</div>
           <div style="display:inline-block;margin-left:15px" v-if="agent_id && !is_weixns" @click="goRe">注册</div>
          <!-- <a class="color-999" @click="openShow" style="float:right;margin-right:15px">登录遇到问题？</a> -->
      </p>
      <div class="warp login-other">
        <!-- <h3 class="mui-text-center color-999"><span>其他登录方式</span></h3>
        <div class="clearfix mt1">
          <div class="mui-col-xs-6 mui-pull-left mui-text-center">
            <img src="@/assets/img/login-img2.jpg" />
            <p class="color-999">QQ</p>
          </div>
          <div class="mui-col-xs-6 mui-pull-right mui-text-center">
            <img src="@/assets/img/login-img3.jpg" />
            <p class="color-999">微信</p>
          </div>
        </div> -->
        <!-- <p class="mui-text-center color-999 mt1">
          登录及表示同意<a class="color-main" href="">《用户协议》</a>及<a
            class="color-main"
            href=""
            >《隐私政策》</a
          >
        </p> -->
      </div>
    </div>

      <!-- 问题弹窗开始 -->
    <div class="motal" v-show="showQuestion">
      <div class="motal_box">
        <h3>登录遇到问题？</h3>
        <p>您可以直接拨打我们的客户电话进行反馈，我们将全心全意提供服务！</p>
        <p>联系电话：400-456236；</p>
        <p>工作时间：早上9点-晚上6点；</p>
      </div>
      <img @click="closeShow" src="@/assets/img/tc-close.png" alt="" />
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import {
  sendMessage,
  mobileLogin,
  socialiteCallBackMobile,
  getUserInfo,
} from "@/api/common";
export default {
  name: "bottom",
  components: {},
  components: {
    SrButton,
  },
  data() {
    return {
      login_type: 1, //1验证码登录 2密码登录
      login_loading: false,
      sms_loading: false,
      form: {},
      sms_time: 0,
      showQuestion: false,
      is_chart: false,
      // 1为h5登陆 2为微信登陆
      weixinType: 1,
      mobile: null,
      code: null,
      is_weixns:false,
      agent_id:null
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  methods: {
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
      var char = new Date().getTime();
      var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
      var sign = this.$md5('char='+ char +'&phone=' + _self.form.phone + '&scenario=LoginCode'+ key)
      sendMessage({ ..._self.form, scenario: "LoginCode",char:char,sign:sign.toUpperCase() }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    sendMessage2() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
          var char = new Date().getTime();
      var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
      // var sign = this.$md5('char='+ char +'&phone=' + _self.form.phone + '&scenario=LoginCode'+ key)
      if(storage.getItem("vipBuy") == 1){
            var md5sign = 'char='+ char +'&phone=' + _self.mobile + '&scenario=VipBuy' + key;
        }else{
            var md5sign = 'char='+ char +'&phone=' + _self.mobile + '&scenario=LoginCode' + key;
      }
      var sign = this.$md5(md5sign)
      sendMessage({ phone: _self.mobile, scenario: storage.getItem("vipBuy") == 1 ? "VipBuy" : "LoginCode",char:char,sign:sign.toUpperCase()}).then(
        (res) => {
          if (res.status === 200) {
            _self.sms_loading = false;
            _self.sms_time = 60;
            var timer = setInterval(function () {
              _self.sms_time--;
              storage.setItem("sms_time", _self.sms_time);
              if (_self.sms_time <= 0) {
                clearInterval(timer);
              }
            }, 1000);
          } else {
            _self.$message.warning(res.msg);
            _self.sms_loading = false;
          }
        }
      );
    },
    login() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      if (!url || url === "/login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      //验证码登录
      mobileLogin(this.form).then((res) => {
        if (res.status === 200) {
          _self.login_loading = false;
          _self.$message.success("登录成功");
          storage.setItem("ACCESS_TOKEN", res.result);
          getUserInfo().then((res) => {
          storage.setItem("userInfo", res.result);
          });
          _self.$router.push(url);
        } else {
          _self.$message.warning(res.msg);
          _self.login_loading = false;
        }
      });
    },
    weixinLogin() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      console.log(url,'跳转链接')
      if (!url || url === "/login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      socialiteCallBackMobile({
        head_portrait_id: storage.getItem("head_portrait_id"),
        weixin_openid: storage.getItem("weixin_openid"),
        unionid: storage.getItem("unionid"),
        nickname: storage.getItem("nicknames"),
        mobile: _self.mobile,
        code: _self.code,
        agent_id:storage.getItem('agent_id')
      }).then((res) => {
        if (res.status === 200) {
          _self.login_loading = false;
          _self.$message.success("登录成功");
          if(storage.getItem('agent_id')){
            storage.removeItem('agent_id');
             _self.$router.push('/vip');
          }else{
          _self.$router.push(url);
          }
          storage.setItem("ACCESS_TOKEN", res.result);
           getUserInfo().then((res) => {
          storage.setItem("userInfo", res.result);
          });
        } else {
          _self.$message.warning(res.msg);
          _self.login_loading = false;
        }
      });
    },
    goPassword() {
      this.$router.push("/passwordLogin");
    },
    goIndex() {
      this.$router.push("/");
    },
    goRe(){
      this.$router.push('/register')
    },
    openShow() {
      //console.log(1)
      this.showQuestion = true;
    },
    closeShow() {
      this.showQuestion = false;
    },
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.is_weixns = this.is_weixn()
    console.log(this.weixinType,'weixinType')
    if (this.$route.query.weixinType) {
      this.weixinType = this.$route.query.weixinType;
    }
    if(storage.getItem('agent_id')){
      this.agent_id = storage.getItem('agent_id')
    }
  },
};
</script>
<style scoped>
.login-other {
  position: absolute;
  width: 97%;
  bottom: 1rem;
}

.motal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.motal_box {
  background: url(../../assets/img/txtx.png) no-repeat;
  margin: 0 auto;
  width: 90%;
  height: 322px;
  padding: 60px 50px;
  margin-top: 200px;
  background-size: 100%;
}
.motal_box h3 {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
}
.motal_box p {
  margin-bottom: 10px;
}
.motal img {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.wechart_btn {
  width: 90%;
  margin: 0 auto;
  margin-top: 200px;
  text-align: center;
  background: #04be02;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  border-radius: 50px;
}
</style>