<template>
    <div style="height: 100%;width: 100%">
        <div id="dianbo"
             style="width:100%;height:100%">
            <div class="bjc-player-wrapper"
                 style="height: 100%">
                <video class="video video-js vjs-default-skin"
                       style="height: 100%">
                </video>
            </div>
        </div>
    </div>
</template>

<script>
    import {updateVideoProgress} from "@/api/common"

    export default {
        name: "index",
        props: {
            token: {}
        },
        data() {
            return {
                player: {},
                video_id: 0,
                playing: false
            }
        },

        methods: {
            init(token, video_id) {
                var _self = this
                _self.video_id = video_id
                console.log(token)
                console.log(video_id)
                var player = new BjcPlayer($('#dianbo').find('.video')[0], {
                    token: token,
                    vid: video_id,
                    autoplay: false,
                    showLogo: true,
                    playbackRates: [0.7, 1, 1.2, 1.5, 2], // 倍速 传入 [] 隐藏倍速列表
                    privateDomainPrefix: '', // 专属域名前缀 0.0.2版本开始支持
                    onended: function (data) {
                        _self.playing = false
                        var baijia_video_id = _self.video_id
                        var seconds = _self.player.getCurrentTime()
                        updateVideoProgress({baijia_video_id: baijia_video_id, seconds: seconds})
                        _self.$emit('ended', data)
                    },
                    onplay: function (data) {
                        _self.playing = true
                        _self.$emit('play', data)
                    },
                    onpause: function (data) {
                        _self.playing = false
                        _self.$emit('pause', data)
                    },
                    ontimeupdate: function (data) {
                        _self.$emit('timeupdate', data)
                    },
                    onseeked: function (data) {
                    },
                    onerror: function (data) {
                    }
                });
                _self.updateVideoProgress()
                _self.player = player
                _self.$emit('initcomplete', true)
            },
            getCurrentTime() { //获取当前播放时间
                return this.player.getCurrentTime()
            },
            resume() { //暂停后重新播放
                return this.player.resume()
            },
            seek(second) {
                return this.player.seek(second)
            },
            pause(){
               return this.player.pause()
            },
            updateVideoProgress() {
                var _self = this
                setInterval(() => {
                    if (_self.playing) {
                        var baijia_video_id = _self.video_id
                        var seconds = _self.player.getCurrentTime()
                        updateVideoProgress({baijia_video_id: baijia_video_id, seconds: seconds})
                    }
                }, 5000)
            }
        }
    }
</script>

<style scoped>
    .video-js {
        width: 100%;
        height: 431px;
    }


    /* 媒体查询 */
    @media screen and (max-width: 1200px) {
        .video-js {
            width: 100%;
            height: 320px;
        }
    }
</style>
