<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">添加收货地址</h1>
    </header>
    <div class="mui-content">
      <form class="mui-input-group add-address">
        <div class="mui-input-row">
          <label>收货人：</label>
          <input type="text" v-model="name" placeholder="请输入姓名" />
        </div>
        <div class="mui-input-row">
          <label>手机号：</label>
          <input type="text" v-model="mobile" placeholder="请输入手机号" />
        </div>
        <div class="mui-input-row">
          <label>所在地区：</label>
          <div style="display: inline-block; width: 58%">
            <van-cell is-link @click="showPopup" value-key="name">{{
              defult
            }}</van-cell>
          </div>
        </div>
        <div class="mui-input-row">
          <label>详细地址：</label>
          <input type="text" v-model="address" placeholder="请输入详细地址" />
        </div>
        <div class="mui-input-row">
          <label>默认收货：</label>
          <van-switch
            v-model="checked"
            size="20"
            style="margin-top: 10px"
            @change="changeSwitch"
          />
        </div>
        <div class="mui-text-center mt1">
          <button class="member-but" type="button" @click="addLocation">
            保存
          </button>
        </div>
      </form>
    </div>
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="show = false"
         active-color="#3561c7"
        @finish="onFinish"
        :field-names="fieldNames"
      />
    </van-popup>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import { Dialog } from "vant";
import { Cascader } from "vant";
import { addLocation, getLocation } from "@/api/common";
export default {
  name: "addAddress",
  data() {
    return {
      list: [],
      areaList,
      show: false,
      defult: "请选择",
      district_id: null,
      name: null,
      address: null,
      mobile: null,
      is_default: 0,
      checked: false,
      options: [],
      cascaderValue: "",
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
    };
  },
  mounted() {},
  methods: {
    onChange(value, index) {},
    onCancel() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    loadLocation() {
      let _this = this;
      getLocation({}).then((res) => {
        var data = res.result;
        data.map((n) => {
          n.id = n.province_id;
          if (n.city.length > 0) {
            n.children = n.city;
            n.children.map((s) => {
              if (s.district.length > 0) {
                s.id = s.city_id;
                s.children = s.district;
                s.children.map((t) => {
                  t.id = t.district_id;
                });
              } else {
                s.children = [];
              }
            });
          } else {
            n.children = [];
          }
        });
        // _this.columns = data;
        console.log(data);
        _this.options = data;
      });
    },
    changeSwitch(value) {
      console.log(value);
      if (value) {
        this.is_default = 1;
      } else {
        this.is_default = 0;
      }
    },
    onFinish({ selectedOptions }) {
      let _this = this;
      this.district_id = selectedOptions[selectedOptions.length - 1].id;
      _this.defult = "";
      selectedOptions.map((n, index) => {
        _this.defult += n.name;
      });
      this.show = false;
    },
    addLocation() {
      let _this = this;
      if (_this.name == null) {
        Dialog.alert({
          message: "请输入姓名",
        }).then(() => {});
        return;
      }
      if (_this.mobile == null) {
        Dialog.alert({
          message: "请输入手机号",
        }).then(() => {});
        return;
      }
      if (_this.district_id == null) {
        Dialog.alert({
          message: "请选择所在地区",
        }).then(() => {});
        return;
      }
      if (_this.is_default == null) {
        Dialog.alert({
          message: "请输入详细地址",
        }).then(() => {});
        return;
      }
      addLocation({
        district_id: _this.district_id,
        name: _this.name,
        address: _this.address,
        mobile: _this.mobile,
        is_default: _this.is_default,
      }).then((res) => {
        console.log(res, "------添加");
        if (res.status == 200) {
          Dialog.alert({
            message: "操作成功！",
          }).then(() => {
            _this.$router.push("/pay/addressList");
          });
        } else {
          Dialog.alert({
            message: res.msg,
          }).then(() => {});
        }
      });
    },
  },
  created() {
    this.loadLocation();
  },
};
</script>
<style scoped>
>>> .van-cell {
  padding: 10px 0;
}
>>> .van-button--large {
  color: #3561c7 !important;
}
</style>