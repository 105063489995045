import request from '@/utils/request'
// 获取网校分类及其轮播图、班型
export function typelist(parameter) {
    return request({
        url: '/api/school/course_category/list',
        method: 'post',
        data: parameter
    })
}
export function list(parameter) {
    return request({
        url: '/api/school/course_type_item/list',
        method: 'post',
        data: parameter
    })
}
export function topList(parameter) {
    return request({
        url: '/api/school/course_category/recommend',
        method: 'post',
        data: parameter
    })
}
// 8.20更换课程页面列表接口（接口已优化请求时间）
export function recommendSpecial(parameter) {
    return request({
        url: '/api/school/course_category/recommendSpecial',
        method: 'post',
        data: parameter
    })
}

// 11.29更换课程页面列表接口（优化排序）
export function recommend_special(parameter) {
    return request({
        url: '/api/school/course_category/recommend_special',
        method: 'post',
        data: parameter
    })
}
export function info(parameter) {
    return request({
        url: '/api/school/course/info',
        method: 'post',
        data: parameter
    })
}
export function comment_list(parameter) {
    return request({
        url: '/api/school/course/comment_list',
        method: 'post',
        data: parameter
    })
}
// 获取课程包详情
export function coursePackage(parameter) {
    return request({
        url: '/api/school/coursePackage/info',
        method: 'post',
        data: parameter
    })
}
// 获取某课程包评论列表
export function commentList(parameter) {
    return request({
        url: '/api/school/coursePackage/commentList',
        method: 'post',
        data: parameter
    })
}