<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
			<h1 class="mui-title">成公有声</h1>
		</header>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <!-- 电台banner -->
        <div class="swiper-container banner inside-ban">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in bannerList"  @click="goBanner(i.jump_id,i.jump)">
              <img :src="i.image.url" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <!-- 网校导航 -->
        <div class="swiper-container mui-text-center wx-menu">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in iconList">
              <router-link
                :to="{
                  path: '/radio/list',
                  query: { radio_category_id: i.radio_category_id,name:i.name},
                }"
              >
                <a>
                  <img style="margin-bottom: 10px" :src="i.icon.url" />
                  <p>{{ i.name }}</p>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="radio-list bg-fff">
        <h3 class="radio-listT">课程推荐</h3>
        <ul class="clearfix">
          <li class="mui-col-xs-6 mui-pull-left" v-for="i in list">
            <router-link
              :to="{ path: '/radio/detail', query: { id: i.radio_id } }"
            >
              <a>
                <p class="pos-rel">
                  <img :src="i.image.url" />
                  <span class="radio-st" v-if="i.audition == 1"
                    ><img src="@/assets/img/radio-icon1.png" /> 试听</span
                  >
                </p>
                <h3>{{ i.name }}</h3>
                <p class="color-666">播放量{{ i.playback_volume }}万次</p>
              </a>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <Bottom :act="3" style="padding-bottom:50px"></Bottom>
  </div>
</template>
<script>
import Mui from "../../assets/js/mui.min.js";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
import { banner, getlist, recommendList } from "@/api/radio";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      bannerList: [],
      iconList: [],
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        var swiper = new Swiper(".inside-ban", {
          pagination: {
            el: ".swiper-pagination",
          },
           autoplay : 2000,
          autoplay: {
          disableOnInteraction: false
      }
        });
        // 电台导航
        var swiper = new Swiper(".wx-menu", {
          slidesPerView: 4,
        });
      }, 200);
    });
  },
  methods: {
    loadBanner() {
      let _this = this;
      banner({}).then((res) => {
        console.log(res,'-=-----')
        _this.bannerList = res.result;
      });
    },
    getlist() {
      let _this = this;
      getlist({}).then((res) => {
        _this.iconList = res.result;
      });
    },
    recommendList() {
      let _this = this;
      recommendList({ page_size: 100 }).then((res) => {
        console.log(res, "推荐");
        _this.list = res.result.items;
      });
    },
    goBanner(jump_id,jump){
      if(jump == 1){
       this.$router.push({path:'/school/courseDetail',query:{course_id:jump_id}})
      }else if(jump == 2){
       this.$router.push({path:'/school/courseGroupDetail',query:{course_package_id:jump_id}})
      }else if(jump == 3){
         this.$router.push({path:'/radio/detail',query:{id:jump_id}})
      }else{
         this.$router.push({path:'/school/detail',query:{id:jump_id}})
      }
    }
  },
  created() {
    this.loadBanner();
    this.getlist();
    this.recommendList();
  },
};
</script>
<style scoped>
/* .mui-pull-left:last-child {
  margin-bottom: 30px;
} */
</style>