import request from '@/utils/request'
export function banner(parameter) {
    return request({
        url: '/api/wares/banner',
        method: 'post',
        data: parameter
    })
}
export function category(parameter) {
    return request({
        url: '/api/wares/category',
        method: 'post',
        data: parameter
    })
}
export function list(parameter) {
    return request({
        url: '/api/wares/list',
        method: 'post',
        data: parameter
    })
}
// 查看商品详情
export function detail(parameter) {
    return request({
        url: '/api/wares/detail',
        method: 'post',
        data: parameter
    })
}
// 获取指定商品回复列表
export function commentList(parameter) {
    return request({
        url: '/api/wares/commentList',
        method: 'post',
        data: parameter
    })
}
// 点赞评论
export function like(parameter) {
    return request({
        url: '/api/wares/like',
        method: 'post',
        data: parameter
    })
}