<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top" style="background: none">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-fff"
      ></a>
      <h1 class="mui-title color-fff">放弃支付</h1>
    </header>
    <div class="mui-content">
      <div class="warp bg-fff pay mui-text-center">
        <p><img src="@/assets/img/pay-img.jpg" /></p>
        <h4 class="color-main">支付失败</h4>
        <p class="color-999">请您重新下单购买</p>
        <p class="mt1"><a @click="goIndex">返回首页</a></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fail",
  data() {
    return {
    };
  },
  methods: {
      goIndex(){
          this.$router.push('/')
      }
  },
  created() {
  
  },
};
</script>