<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">邀请注册</h1>
    </header>
    <div class="mui-content">
      <div class="warp login">
        <h3 class="mui-text-center">
          <img class="login-logo" src="@/assets/img/login-img1.jpg" />
        </h3>
        <ul>
          <li>
            <input
              placeholder="请输入手机号码"
              v-model="form.phone"
              type="text"
              id=""
              value=""
            />
          </li>
          <li style="position: relative">
            <input
              placeholder="请输入验证码"
              v-model="form.code"
              type="text"
              id=""
              value=""
            />
            <SrButton
              :disabled="sms_time !== 0"
              @click="sendMessage"
              class="login-hqyzm"
              height="33px"
              padding="0 20px"
              font-size="14px"
              style="position: absolute; right: 0; bottom: 26px"
              :loading="sms_loading"
            >
              <span v-if="sms_time === 0">获取验证码</span>
              <span v-else>重新发送({{ sms_time }})</span>
            </SrButton>
          </li>
        </ul>
        <SrButton style="width: 100%" @click="login" :loading="login_loading">
          邀请注册
        </SrButton>
      </div>
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { Dialog } from "vant";
import { sendMessage, shareRegister } from "@/api/common";
export default {
  name: "bottom",
  components: {},
  components: {
    SrButton,
  },
  data() {
    return {
      login_type: 1, //1验证码登录 2密码登录
      login_loading: false,
      sms_loading: false,
      form: {},
      sms_time: 0,
      showQuestion: false,
      invitation_user_id: null,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  methods: {
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
        // var char = new Date().getTime();
        var char = 123123;
        var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
        if(storage.getItem("vipBuy") == 1){
            var md5sign = 'char='+ char +'&phone=' + _self.form.phone + '&scenario=VipBuy' + key;
          }else{
            var md5sign = 'char='+ char +'&phone=' + _self.form.phone + '&scenario=ShareRegisterCode' + key;
        }
        var sign = this.$md5(md5sign)
      sendMessage({
        ..._self.form,
        scenario:
          storage.getItem("vipBuy") == 1 ? "VipBuy" : "ShareRegisterCode",
          char:char,
          sign:sign.toUpperCase()
      }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },

    login() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      if (!url || url === "/login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      //验证码登录
      shareRegister({
        ...this.form,
        invitation_user_id: _self.invitation_user_id
          ? _self.invitation_user_id
          : 0,
        agent_id: storage.getItem("agent_id"),
      }).then((res) => {
        console.log(res,'账号注册')
        if (res.status === 200) {
          _self.login_loading = false;
          _self.$message.success("注册成功");
          storage.setItem("ACCESS_TOKEN", res.result);
          if (storage.getItem("agent_id")) {
            storage.removeItem("agent_id");
          }
          _self.$router.push(url);
        } else {
          if (res.msg == "您已经注册过了") {
            Dialog.confirm({
              title: "您已经注册过了",
              message: "是否为您跳转登录",
            })
              .then(() => {
                this.$router.push("/login");
              })
              .catch(() => {
                // on cancel
              });
          } else {
            _self.$message.warning(res.msg);
            _self.login_loading = false;
          }
        }
      });
    },
    goPassword() {
      this.$router.push("/passwordLogin");
    },
    goIndex() {
      this.$router.push("/");
    },
  },
  created() {
    this.invitation_user_id = this.$route.query.share_user_id;
  },
};
</script>
<style scoped>
.login-other {
  position: absolute;
  width: 97%;
  bottom: 1rem;
}
</style>