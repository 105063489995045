<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import storage from "@/utils/storage";
export default {
  name: "App",
  components: {},
  computed: {},
  methods: {
    
  },
  created() {
  },
};
</script>

<style>
@import "./assets/css/mui.css";
@import "./assets/css/app.css";
.banner {
  margin: 0.8em !important;
}
.kecheng-listT {
  padding: 0.8rem 0 !important;
}
</style>
