<template>
    <div id="box" :class="type" @click="$emit('click')">
        <a-button
                :disabled="disabled"
                type="primary"
                :loading="loading"
                class="button"
                :style="{
                        height:height,
                        lineHeight:height,
                        borderRadius:borderRadius,
                        padding:padding,
                        fontSize:fontSize,
                }">
            <slot></slot>
        </a-button>
    </div>
</template>

<script>
    export default {
        name: "index",
        props: {
            loading: {
                default() {
                    return false
                }
            },
            type: {
                default: 'solid',
            },
            height: {
                default: '50px',
            },
            borderRadius: {
                default: '25px 25px',
            },
            padding: {
                default: '0 50px',
            },
            disabled: {
                default() {
                    return false
                }
            },
            fontSize: {
                default: '20px',
            }
        }
    }
</script>

<style scoped>
    .button{
        width: 100%;
    }
    .solid >>> .ant-btn {
        background-color: #3561C7;
        border: none;
    }

    .solid >>> .ant-btn-primary[disabled] {
        background-color: #ccc;
    }

    .hollow >>> .ant-btn {
        background-color: #fff;
        color: #3561C7;
        border:  #3561C7 solid 1px;
    }

    .hollow >>> .ant-btn-primary[disabled] {
        background-color: #ccc;
    }
</style>
