<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <!-- 顶部选项卡滑动 -->
      <div class="swiper-container kecheng-listT mui-text-center">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(i, index) in typeList"
            @click="getTab(index)"
          >
            <span :class="tabIndex == index ? 'active' : ''">{{ i.name }}</span>
          </div>
        </div>
      </div>
    </header>
    <div class="mui-content">
      <!-- 选项卡内容 -->
      <div class="swiper-container kecheng-listM">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(i, index) in typeList">
            <div class="bg-fff o_hidden">
              <!-- 网校banner -->
              <div class="swiper-container banner inside-ban">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide"
                    v-for="item in i.banner"
                    @click="goBanner(item.jump_id, item.jump)"
                  >
                    <img :src="item.image.url" />
                  </div>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <!-- 网校导航 -->
              <div class="swiper-container mui-text-center wx-menu">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="item in i.course_type">
                    <router-link
                      :to="{
                        path: '/school/list',
                        query: {
                          course_category_id: item.course_category_id,
                          course_type_id: item.course_type_id,
                          name: item.name,
                        },
                      }"
                    >
                      <a>
                        <img :src="item.icon.url" />
                        <p>{{ item.name }}</p>
                      </a>
                    </router-link>
                  </div>
                </div>
                <div
                  class="swiper-pagination daohang"
                  v-show="i.course_type.length > 4"
                ></div>
              </div>
            </div>
            <!-- 网校列表 -->
            <div class="wx-list" v-for="i in list">
             
                <div @click="goCourseDetail(i.goods.relation.course_id)" v-if="i.goods.type == 1">
                  <p
                    class="color-red"
                    :style="'color:' + i.goods.relation.subtitle_color"
                  >
                    {{ i.goods.relation.subtitle }}
                  </p>
                  <h4>{{ i.goods.relation.name }}</h4>
                  <!-- <p class="color-999">{{ i.start }}-{{ i.end }}</p> -->
                  <div class="wx-listImg mt05 clearfix">
                    <ul>
                      <li
                        class="mui-text-center"
                        v-for="item in i.goods.relation.teacher"
                      >
                        <img
                          style="border-radius: 50%; width: 48px; height: 48px"
                          :src="item.avatar.url"
                        />
                        <p class="color-999">{{ item.name }}</p>
                      </li>
                    </ul>
                  </div>
                  <div class="wx-listPrice clearfix">
                    <p
                      class="mui-pull-left color-999"
                      v-if="i.goods.notend_pintuan"
                    >
                      限售{{ i.goods.sales_restriction }}人 | 已报名{{
                        i.goods.notend_pintuan.goods.sale_amount
                      }}人
                    </p>
                    <p
                      class="mui-pull-left color-999"
                      v-if="i.goods.notend_seckill"
                    >
                      限售{{ i.goods.sales_restriction }}人 | 已报名{{
                        i.goods.notend_seckill.goods.sale_amount
                      }}人
                    </p>
                    <p
                      class="mui-pull-left color-999"
                      v-if="!i.goods.notend_seckill && !i.goods.notend_pintuan"
                    >
                      限售{{ i.goods.sales_restriction }}人 | 已报名{{
                        i.goods.sale_amount
                      }}人
                    </p>

                    <p class="mui-pull-right color-red f-18">
                      ￥{{ i.order_price }}
                    </p>
                    <h3 class="miaosha">{{ i.real_price }}</h3>
                  </div>
                  <span class="wx-label" v-if="i.vip_free == 1"
                    ><img src="@/assets/img/wx-free.png"
                  /></span>
                  <span class="wx-label" v-if="i.goods.notend_seckill"
                    ><img src="@/assets/img/wx-miaosha.png"
                  /></span>
                  <span class="wx-label" v-if="i.goods.notend_pintuan"
                    ><img src="@/assets/img/wx-pintuan.png"
                  /></span>
                  <span class="wx-label" v-if="i.goods.agreement_class == 1"
                    ><img src="@/assets/img/wx-agreement.png" />
                  </span>
                </div>
              <!-- 课程包 -->
              <div v-else @click="goCourseGroupDetail(i,i.goods.relation.course_package_id)">
                  <p
                    class="color-red"
                    :style="'color:' + i.goods.relation.subtitle_color"
                  >
                    {{ i.goods.relation.subtitle }}
                  </p>
                  <h4>{{ i.goods.relation.name }}</h4>
                  <!-- <p class="mt05">
                    <img :src="i.goods.relation.first_cover.url" />
                  </p> -->
                  <div class="wx-listPrice clearfix">
                    <!-- <p class="mui-pull-left color-999">
                      限售{{ i.goods.sales_restriction }}人 | 已报名{{
                        i.goods.sale_amount
                      }}人
                    </p> -->
                    <!-- <h3 class="miaosha">{{ i.real_price }}</h3> -->
                    <p
                      class="mui-pull-right color-red f-18"
                      v-if="i.goods.orderprice"
                    >
                      ￥{{ i.goods.orderprice }}
                    </p>
                    <p class="mui-pull-right color-red f-18" v-else>
                      ￥{{ i.order_price }}
                    </p>
                  </div>
              </div>
            </div>
          </div>
          <!-- 公务员内容结束 -->
        </div>
      </div>
    </div>
    <Bottom :act="2" style="padding-bottom: 50px"></Bottom>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { typelist, list, topList, recommend_special } from "@/api/course";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      typeList: [],
      list: [],
      tabIndex: 0,
    };
  },
  mounted() {
    let _this = this;
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
    this.$nextTick(() => {
      setTimeout(() => {
        var swiper = new Swiper(".inside-ban", {
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: 2000,
          autoplay: {
            disableOnInteraction: false,
          },
        });
        // 课程列表
        var galleryThumbs = new Swiper(".kecheng-listT", {
          freeMode: true,
          freeModeMomentumRatio: 0,
          slidesPerView: "auto",
          onSlideChangeEnd: function (swiper) {
            console.log(swiper);
          },
        });
        var galleryTop = new Swiper(".kecheng-listM", {
          spaceBetween: 10,
          allowTouchMove: false,
          thumbs: {
            swiper: galleryThumbs,
          },
        });
        // 网校导航
        var swiper = new Swiper(".wx-menu", {
          slidesPerView: 4,
          pagination: {
            el: ".swiper-pagination",
          },
        });
        if (_this.$route.query.tabIndex) {
          galleryThumbs.slideTo(_this.tabIndex, 1000, false);
          var course_category_id = _this.typeList[_this.tabIndex].course_category_id;
          var course_type_id = _this.typeList[_this.tabIndex].course_type[0].course_type_id;
          _this.loadList(course_category_id, course_type_id);
        }
      }, 1000);
    });
  },
  methods: {
    // 获取分类
    loadType: function () {
      let _this = this;
      typelist({}).then((res) => {
        console.log(res, "分类");
        _this.typeList = res.result;
        var course_category_id = res.result[0].course_category_id;
        var course_type_id = res.result[0].course_type[0].course_type_id;
        _this.loadList(course_category_id, course_type_id);
      });
    },
    // 加载列表
    loadList(course_category_id, course_type_id) {
      let _this = this;
      recommend_special({
        course_category_id: course_category_id,
        course_type_id: course_type_id,
        page: 1,
        page_size: 100,
      }).then((res) => {
        var data = res.result.items;
        console.log(data, "ddddd");
        //  if (storage.getItem('userInfo') == null) {
        //   var url = _this.GetUrlRelativePath();
        //   storage.setItem("LOGIN_SKIP", url);
        //   _this.$router.push({path:'/login?action=login'})
        // }
        var userInfo = storage.getItem("userInfo");
        //价格计算规则 如果课程有会员价显示会员价和原价（划线价） 不是会员则显示原价不显示划线价  如果该课程没有会员价格则直接显示原价不显示划线价   如果该课程是活动课程 则显示活动价和原价
        data.map(function (n) {
          if (n.course_package_id) {
            //   课程包课程-------------------------------
            if (n.goods.notend_pintuan || n.goods.notend_seckill) {
              if (n.goods.notend_seckill) {
                n.order_price = n.goods.notend_seckill.goods.order_price;
                n.real_price = n.goods.real_price;
              }
              if (n.goods.notend_pintuan) {
                n.order_price = n.goods.notend_pintuan.goods.order_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              if (n.goods.vip_price.length > 0) {
                // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
                if (userInfo) {
                  if (userInfo.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        userInfo.now_user_member.user_member_config_id
                      ) {
                        n.order_price = s.price;
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                  n.real_price = n.goods.real_price;
                } else {
                  n.order_price = n.goods.order_price;
                }
              } else {
                n.order_price = n.goods.order_price;
              }
            }
          } else {
            // 普通课程------------------------------------------------------
            if (n.goods.notend_pintuan || n.goods.notend_seckill) {
              if (n.goods.notend_seckill) {
                n.order_price = n.goods.notend_seckill.goods.order_price;
                n.real_price = n.goods.real_price;
              }
              if (n.goods.notend_pintuan) {
                n.order_price = n.goods.notend_pintuan.goods.order_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              if (n.goods.vip_price.length > 0) {
                // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
                if (userInfo) {
                  if (userInfo.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        userInfo.now_user_member.user_member_config_id
                      ) {
                        n.order_price = s.price;
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                  n.real_price = n.goods.real_price;
                } else {
                  n.order_price = n.goods.order_price;
                }
              } else {
                n.order_price = n.goods.order_price;
              }
            }
          }
          if (n.order_price == n.real_price) {
            n.real_price = "";
          }
        });
        _this.list = data;
      });
    },
    getTab(index) {
      console.log(index);
      this.tabIndex = index;
      var course_category_id = this.typeList[index].course_category_id;
      var course_type_id = this.typeList[index].course_type[0].course_type_id;
      this.loadList(course_category_id, course_type_id);
    },
    goBanner(jump_id, jump) {
      if (jump == 1) {
        // this.$router.push({
        //   path: "/school/courseDetail",
        //   query: { course_id: jump_id },
        // });
        window.location.href="https://wap.srgongkaow.com/school/courseDetail?course_id=" + jump_id
      } else if (jump == 2) {
        this.$router.push({
          path: "/school/courseGroupDetail",
          query: { course_package_id: jump_id },
        });
      } else if (jump == 3) {
        this.$router.push({ path: "/radio/detail", query: { id: jump_id } });
      } else {
        this.$router.push({ path: "/school/detail", query: { id: jump_id } });
      }
    },
    goCourseDetail(id){
        window.location.href="https://wap.srgongkaow.com/school/courseDetail?course_id=" + id
    },
       goCourseGroupDetail(item,id){
        //  style_temp 0课程包详情样式 1课程包列表样式
         if(item.goods.relation.style_temp == 0){
           window.location.href="https://wap.srgongkaow.com/school/courseGroupDetail?course_package_id=" + id
         }else{
            window.location.href="https://wap.srgongkaow.com/courseGroupList?id=" + id
         }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
    
  },
  created() {
    if (this.$route.query.tabIndex) {
      this.tabIndex = this.$route.query.tabIndex;
    }
    this.loadType();
  },
};
</script>
<style scoped>
@import "../../assets/css/swiper.min.css";
/* .wx-list:last-child{
  padding-bottom: 90px;
} */
.miaosha {
  float: right;
  font-size: 14px;
  padding-top: 0px;
  padding-left: 10px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
}
.daohang {
  bottom: 0;
}
.daohang >>> .swiper-pagination-bullet {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 20px;
  height: 2px;
}
.wx-listPrice p {
  margin: 0 !important;
}
.kecheng-listT .swiper-slide {
  width: 20%;
}
.kecheng-listT .swiper-slide-thumb-active {
}
.kecheng-listT .active {
  color: #3561c7;
}
.kecheng-listT .active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.3rem;
  background: rgba(53, 97, 199, 0.5);
  z-index: -1;
  border-radius: 0.2rem;
}
</style>