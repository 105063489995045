<template>
  <div>
    <div class="motals">
      <div class="motals_box">
        <div class="motals_title">参与学习</div>
        <div class="login" style="margin-top:0px">
          <ul style="margin-top:0px">
            <li>
              <input
                placeholder="请输入手机号码"
                v-model="form.phone"
                type="text"
                id=""
                value=""
              />
            </li>
            <li style="position: relative">
              <input
                placeholder="请输入验证码"
                v-model="form.code"
                type="text"
                id=""
                value=""
              />
              <SrButton
                :disabled="sms_time !== 0"
                @click="sendMessage"
                class="login-hqyzm"
                height="33px"
                padding="0 20px"
                font-size="14px"
                style="position: absolute; right: 0; bottom: 26px"
                :loading="sms_loading"
              >
                <span v-if="sms_time === 0">获取验证码</span>
                <span v-else>重新发送({{ sms_time }})</span>
              </SrButton>
            </li>
          </ul>
        <SrButton style="width: 100%;margin-bottom:20px" @click="login" :loading="login_loading">
          马上参与
        </SrButton>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { sendMessage,user_share } from "@/api/common";
export default {
  name: "bottom",
  components: {},
  components: {
    SrButton,
  },
  props:{
      share_user_id:String,
      share_id:String
  },
  data() {
    return {
      login_type: 1, //1验证码登录 2密码登录
      login_loading: false,
      sms_loading: false,
      form: {},
      sms_time: 0,
      showQuestion: false,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  methods: {
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
        var char = new Date().getTime();
      var key = 'xCf188EYN4arKCXqo3nUYDumTccz7'
      var sign = this.$md5('char='+ char +'&phone=' + _self.form.phone + '&scenario=ShareCode'+ key)
      sendMessage({ ..._self.form, scenario: "ShareCode",char:char,sign:sign.toUpperCase() }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },
    
    login() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      if (!url || url === "/login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      //验证码登录
      user_share({phone:_self.form.phone,code:_self.form.code,share_user_id:_self.share_user_id,share_id:_self.share_id}).then((res) => {
        if (res.status === 200) {
          _self.login_loading = false;
          _self.$message.success("操作成功");
          _self.$emit('motalShow',false)
        } else {
          _self.$message.warning(res.msg);
          _self.login_loading = false;
        }
      });
      //   if (_self.login_type === 1) {

      //   } else {
      //     login(this.form).then((res) => {
      //       if (res.status === 200) {
      //         _self.login_loading = false;
      //         _self.$message.success("登录成功");
      //         storage.setItem("ACCESS_TOKEN", res.result);
      //         _self.$router.push(url);
      //       } else {
      //         _self.$message.warning(res.msg);
      //         _self.login_loading = false;
      //       }
      //     });
      //   }
    },
    goPassword() {
      this.$router.push("/passwordLogin");
    },
    goIndex() {
      this.$router.push("/");
    },
  },
  created() {},
};
</script>
<style scoped>
.motals {
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.motals_box {
  background: #ffffff;
  width: 90%;
  padding: 15px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 200px;
}
.motals_title {
  margin-bottom: 14px;
  text-align: center;
  font-size: 18px;
  color: #3561c7;
}
</style>