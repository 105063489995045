<template>
    <div>
        <div class="cover">
            <img style="transition: all .5s;margin:0 auto;border-radius:50%;"
                 width="300px"
                 height="300px"
                 :class="[play?'trans':'notrans']"
                 :src="cover">
            <div id="play">
                <a-icon v-if="!play"
                        @click="handlePlay"
                        style="cursor: pointer"
                        type="play-circle"
                        theme="filled"/>
                <a-icon v-else
                        @click="handlePause"
                        style="cursor: pointer"
                        type="pause-circle"
                        theme="filled"/>
            </div>
        </div>
        <a-slider id="test"
                  v-model="now"
                  :disabled="!play"
                  @change="handleChange"
                  :tipFormatter="timeFormatter"
                  :max="length"/>
        <BjDianbo ref="BjDianbo"
                  style="height: 0;overflow: hidden"
                  @onended="now = length"
                  @initcomplete="loading = false"
                  @timeupdate="timeupdate"></BjDianbo>
    </div>
</template>

<script>
    import BjDianbo from '@/components/BjDianbo'

    export default {
        name: "index",
        props: {
            able: {},
            cover: {}
        },
        computed: {
            ProgressOverLength() {
                var num = (this.now * 100) / this.length;
                num = num > 100 ? 100 : num
                return {
                    width: num + '%'
                }
            }
        },
        components: {
            BjDianbo
        },
        data() {
            return {
                video_id: 0,
                length: 1,
                now: 0,
                play: false,
                loading: true
            }
        },
        methods: {
            init(token, video_id, length) {
                this.video_id = video_id
                this.$refs.BjDianbo.init(token, video_id)
                this.length = parseInt(length)
            },
            handlePlay() {
                console.log(1,this.able)
                if (this.able){
                    this.play = true
                    this.$refs.BjDianbo.resume()
                }
            },
            handlePause() {
                this.play = false
                console.log(this.play)
                this.$refs.BjDianbo.pause()
            },
            handleChange(e) {
                this.$refs.BjDianbo.seek(e)
            },
            timeupdate(e) {
                this.now = e.currentTime
            },
            timeFormatter(result) {
                var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
                var m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
                var s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
                return h + ":" + m + ":" + s;
            }
        }
    }
</script>

<style scoped>
    .cover {
        position: relative;
    }

    #play {
        font-size: 50px;
        position: absolute;
        line-height: 350px;
        width: 100%;
        text-align: center;
        color: #333;
        top: -10%;
    }

    .notrans {
        transition: all 1s;
    }

    .trans {
        border-radius: 50%;
        animation: rotate 300s linear infinite;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg)
        }
        to {
            transform: rotate(360deg)
        }
    }

</style>
