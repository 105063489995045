import request from '@/utils/request'
export function banner(parameter) {
    return request({
        url: '/api/radio/radio/banner',
        method: 'post',
        data: parameter
    })
}
// 获取电台课程分类
export function getlist(parameter) {
    return request({
        url: '/api/radio/category/getlist',
        method: 'post',
        data: parameter
    })
}
// 获取电台推荐课程列表
export function recommendList(parameter) {
    return request({
        url: '/api/radio/radio/recommendList',
        method: 'post',
        data: parameter
    })
}
// 获取电台课程详情
export function info(parameter) {
    return request({
        url: '/api/radio/radio/info',
        method: 'post',
        data: parameter
    })
}
// 获取电台评论列表
export function commentList(parameter) {
    return request({
        url: '/api/radio/radio/commentList',
        method: 'post',
        data: parameter
    })
}
// 获取电台课程大纲及子章节列表
export function chapterList(parameter) {
    return request({
        url: '/api/radio/chapter/list',
        method: 'post',
        data: parameter
    })
}
// 获取某分类下课程列表
export function Radiogetlist(parameter) {
    return request({
        url: '/api/radio/radio/getlist',
        method: 'post',
        data: parameter
    })
}
export function video(parameter) {
    return request({
        url: '/api/radio/subchapter/video',
        method: 'post',
        data: parameter
    })
}
