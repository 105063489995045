<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">课程详情</h1>
    </header>
    <!-- 底部 -->
    <img
      src="@/assets/img/shixue.png"
      style="position: fixed; bottom: 75px; left: 26%; z-index: 100"
      v-if="detail.try_learn > 0 && detail.user_unlock_count == 0"
      @click="shixue"
    />
    <nav
      class="mui-bar mui-bar-tab xq-foot"
      v-if="detail.user_unlock_count == 0"
    >
      <div
        class="mui-tab-item mui-text-left"
        :style="detail.goods.notend_pintuan ? 'width: 12%' : ''"
      >
        <span class="color-red f-22"
          >￥{{ detail.order_price }}
          <div class="miaosha">{{ detail.real_price }}</div>
        </span>
        <span class="color-red">已报名{{ detail.goods.sale_amount }}人</span>
      </div>
      <div
        class="mui-tab-item mui-text-right"
        style="width: 30%"
        v-if="detail.goods.notend_pintuan"
      >
        <a class="pintuan-but" style="float: right" @click="payPinTuan"
          >发起拼团<small
            >({{ detail.goods.notend_pintuan.number }}人成团)</small
          >
        </a>
        <a
          class="pintuan-but"
          style="
            display: inline-block;
            height: 40px;
            float: right;
            line-height: 40px;
            background: #eebfd7;
          "
          @click="goPay"
          >单独购买</a
        >
      </div>
      <div class="mui-tab-item mui-text-right" v-else>
        <a class="xq-footBut" @click="goPay">{{detail.order_price==0?'免费报名':detail.order_price=='0.00'?'免费报名':'立即购买'}}</a>
      </div>
    </nav>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <div class="video">
          <img :src="detail.image.url" />
        </div>
        <div class="video-tex">
          <h4>{{ detail.name }}</h4>
          <h5 class="color-666" :style="'color:' + detail.subtitle_color">
            {{ detail.subtitle }}
          </h5>
          <!-- <h5 class="color-999">开课时间：{{ detail.start }} - {{ detail.end }}</h5> -->
          <h5 class="color-999">
            已报名：{{ detail.goods.sale_amount }}人 | 剩余{{
              detail.goods.surplus_seat
            }}人
          </h5>
          <div class="border-top mt1" style="overflow: hidden">
            <div class="radio-teacher mui-pull-left">
              <ul style="margin-bottom: 0">
                <li class="mui-text-center" v-for="i in detail.teacher">
                  <img style="width: 40px; height: 40px" :src="i.avatar.url" />
                  <p class="color-999">{{ i.name }}</p>
                </li>
              </ul>
            </div>
            <!-- <div class="mui-pull-right djs color-pink mui-text-center mt1">
              <p style="margin-bottom: 0">
                <img src="@/assets/img/miaosha.png" />报名倒计时
              </p>
              <a-statistic-countdown
                :value="detail.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
            </div> -->
          </div>
        </div>
      </div>
      <!-- 拼团 -->
      <div class="bg-fff mt1" v-if="detail.goods.notend_pintuan">
        <PinTuan
          :pintuan_id="detail.goods.notend_pintuan.pintuan_id"
          :goods="detail.goods"
        ></PinTuan>
      </div>
      <div class="mui-slider kecheng-tab">
        <div
          class="
            mui-slider-indicator
            mui-segmented-control
            mui-segmented-control-inverted
          "
        >
          <a class="mui-control-item mui-active" @click="getTab(0)">
            课程介绍
          </a>
          <a class="mui-control-item" @click="getTab(1)"> 学员笔记 </a>
          <a class="mui-control-item" @click="getTab(2)"> 训练主题 </a>
        </div>
        <div class="mui-slider-group">
          <div
            class="mui-slider-item mui-control-content mui-active"
            v-if="tabIndex == 0"
          >
            <div v-html="imgWH(detail.introduce)"></div>
          </div>
          <div class="mui-slider-item mui-control-content" v-if="tabIndex == 1">
            <div v-if="stuList.length > 0">
              <dl class="pinglun clearfix" v-for="i in stuList">
                <dt class="mui-pull-left">
                  <img class="dis-inb tx" :src="i.user.head_portrait.url" />
                </dt>
                <dd class="mui-pull-right pos-rel">
                  <p>
                    {{ i.user.nickname }}
                    <img
                      class="dis-inb jibie"
                      v-if="i.user.now_user_member"
                      :src="
                        i.user.now_user_member.user_member_config_id == 1
                          ? '/img/jibie.png'
                          : i.user.now_user_member.user_member_config_id == 2
                          ? '/img/jibie2.png'
                          : i.user.now_user_member.user_member_config_id == 3
                          ? '/img/jibie3.png'
                          : i.user.now_user_member.user_member_config_id == 4
                          ? '/img/jibie4.png'
                          : i.user.now_user_member.user_member_config_id == 5
                          ? '/img/jibie5.png'
                          : '/img/jibie6.png'
                      "
                    />
                  </p>
                  <p class="color-666">
                    {{ i.content }}
                  </p>
                  <p class="clearfix">
                    <span v-for="item in i.image">
                      <van-cell is-link @click="showPopup(item.url)"
                        ><img :src="item.url"
                      /></van-cell>
                    </span>
                  </p>
                  <p
                    class="pinglun-zan color-999"
                    @click="clickLike(i.camp_diary_id, i.is_like)"
                  >
                    <img
                      src="@/assets/img/praise.png"
                      v-if="i.is_like == 0"
                    /><img src="@/assets/img/praise-hover.png" v-else />{{
                      i.likes
                    }}
                  </p>
                </dd>
              </dl>
            </div>
          </div>
          <div class="mui-slider-item mui-control-content" v-if="tabIndex == 2">
            <SrSwitch
              style="float: right; margin-bottom: 30px"
              @change="orderChange"
              :list="['正序', '倒序']"
            ></SrSwitch>
            <div
              class="xly-list"
              v-for="i in themeList"
              @click="themeDetail(i.user_clock_count)"
            >
              <h4>{{ i.name }}</h4>
              <p class="color-999">
                {{ i.clock_count }}人已打卡 | {{ i.created_at }}
              </p>
              <span class="mui-text-center" v-if="i.user_clock_count == 0"
                ><img src="/img/xly-icon1.png" /><br />未解锁</span
              >
              <span class="mui-text-center" v-else
                ><img src="/img/xly-icon2.png" /><br />已解锁</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <KeFu></KeFu>
    <InviteBuy
      :share_user_id="share_user_id"
      :share_id="share_id"
      @motalShow="getShow"
      v-show="InviteShow"
    ></InviteBuy>
    <van-popup v-model="imgShow" style="width: 70%">
      <img :src="imgUrl" alt="" />
    </van-popup>
  </div>
</template>
<script>
var jweixin = require("/jweixin-module/index.js");
import storage from "@/utils/storage";
import SrSwitch from "@/components/SrSwitch";
import Mui from "../../assets/js/mui.min.js";
import PinTuan from "@/components/PinTuan";
import KeFu from "@/components/kefu";
import InviteBuy from "@/components/InviteBuy";
import { campDetail, campstuList, theme, campLike } from "@/api/camp";
import { getShare, getSignPackage } from "@/api/common";
import { Dialog } from "vant";
export default {
  name: "bottom",
  components: {
    PinTuan,
    KeFu,
    SrSwitch,
    InviteBuy,
  },
  data() {
    return {
      id: null,
      detail: {},
      tabIndex: 0,
      stuList: [],
      themeList: [],
      order_by: "asc",
      imgShow: false,
      imgUrl: null,
      InviteShow: false,
      share_id: null,
      share_user_id: null,
    };
  },
  mounted() {},
  methods: {
    loadDetail() {
      let _this = this;
      campDetail({ camp_id: _this.id }).then((res) => {
        console.log(res, "详情");
        var data = res.result;
        // if (storage.getItem("userInfo") == null) {
        //   var url = _this.GetUrlRelativePath();

        //   storage.setItem("LOGIN_SKIP", url);
        //   _this.$router.push({ path: "/login?action=login" });
        // }
        var userInfo = storage.getItem("userInfo");
        console.log(userInfo,'个人信息')
        if (data.goods.notend_pintuan || data.goods.notend_seckill) {
          if (data.goods.notend_seckill) {
            data.order_price = data.goods.notend_seckill.goods.order_price;
            data.real_price = data.goods.real_price;
          }
          if (data.goods.notend_pintuan) {
            data.order_price = data.goods.notend_pintuan.goods.order_price;
            data.real_price = data.goods.real_price;
          }
        } else {
          if (data.goods.vip_price.length > 0) {
            // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
            if (userInfo) {
              data.goods.vip_price.map(function (s) {
                if(userInfo.now_user_member){
                  if (
                  s.user_member_config_id ==
                  userInfo.now_user_member.user_member_config_id
                ) {
                  data.order_price = s.price;
                }
                }
              });
              data.real_price = data.goods.real_price;
            } else {
              data.order_price = data.goods.order_price;
            }
          } else {
            data.order_price = data.goods.order_price;
          }
        }
        _this.detail = data;
        // share_user_id有值为分享进入该页面 通过share_user_id获取share_id 如请求的share_id不为空 则弹出参与学习页面
        // if (_this.$route.query.share_user_id) {
        //   _this.loadShare();
        // }
        _this.loadtheme();
      });
    },
    getTab(index) {
      if (index == 1) {
        this.loadcampstuList();
      }
      this.tabIndex = index;
    },
    // 加载学员笔记
    loadcampstuList() {
      let _this = this;
      campstuList({ camp_id: _this.id, page_size: 100 }).then((res) => {
        console.log(res, "学员笔记");
        _this.stuList = res.result.items;
      });
    },
       getSignPackage() {
      let _this = this;
      getSignPackage({
        url: window.location.href,
      }).then((res) => {
        console.log(res, "配置");
        if (res.status == 200) {
          jweixin.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.result.appId, // 必填，公众号的唯一标识
            timestamp: res.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
            signature: res.result.signature, // 必填，签名，见附录1
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "checkJsApi",
            ],
          });
          jweixin.ready(function () {
            jweixin.checkJsApi({
              jsApiList: ["updateAppMessageShareData","updateTimelineShareData"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              },
            });
            jweixin.updateAppMessageShareData({
              title: _this.detail.goods.name, // 分享标题
              desc: _this.detail.subtitle, // 分享描述
              link:
                "https://wap.srgongkaow.com/school/detail?id=" +
                _this.detail.camp_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {
              },
            });
            jweixin.updateTimelineShareData({
              title: _this.detail.goods.name, // 分享标题
              link:
                "https://wap.srgongkaow.com/school/detail?id=" +
                _this.detail.camp_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {
              },
            });
          });
        }
      });
    },
    imgWH(string) {
      //正则替换图片宽高
      if (string) {
        string = string.replace(/ height="\d+"/g, ' height="auto"');
        string = string.replace(/ width="\d+"/g, ' width="100%"');
        return string;
      } else {
        return "";
      }
    },
    loadtheme() {
      let _this = this;
      theme({
        camp_id: _this.id,
        page_size: 100,
        order_by: _this.order_by,
      }).then((res) => {
        _this.themeList = res.result.items;
      });
    },
    themeDetail(type) {
      if (this.detail.user_unlock_count == 0) {
        Dialog.alert({
          message: "请先购买课程",
        }).then(() => {});
      } else {
        this.download();
      }
    },
    download() {
      let _this = this;
      Mui.alert("请到APP端下载课程资料", "温馨提示", function () {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          var loadDateTime = new Date();
          window.location =
            "shengru://goodId=" +
            _this.detail.goods.relation_id +
            "&type=" +
            _this.detail.goods.type; //schema链接或者universal link
          window.setTimeout(function () {
            //如果没有安装app,便会执行setTimeout跳转下载页
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
              window.location = "https://apps.apple.com/cn/app/id1544691960"; //ios下载地址
            } else {
              window.close();
            }
          }, 500);
        } else if (navigator.userAgent.match(/android/i)) {
          var state = null;
          try {
            window.location =
              "srhx://api.srgongkaow.app/openwith?goodId=" +
              _this.detail.goods.relation_id +
              "&type=" +
              _this.detail.goods.type; //schema链接或者universal link
            window.setTimeout(function () {
              window.location = "https://gongkaow.com/app/app.apk"; //android下载地址
            }, 500);
          } catch (e) {}
        }
      });
    },
    orderChange(index) {
      if (index === 0) {
        this.order_by = "asc";
      } else {
        this.order_by = "desc";
      }
      this.loadtheme();
    },
    goPay() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: false,
        },
      });
    },
    payPinTuan() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: true,
        },
      });
    },
    // 点赞笔记
    clickLike(camp_diary_id, is_like) {
      let _this = this;
      if (is_like == 0) {
        _this.campLike(camp_diary_id, 1);
      } else {
        _this.campLike(camp_diary_id, 0);
      }
    },
    campLike(id, status) {
      let _this = this;
      campLike({ camp_diary_id: id, status: status }).then((res) => {
        console.log(res, "点赞");
        if (res.status == 200) {
          _this.loadcampstuList();
        }
      });
    },
    showPopup(url) {
      this.imgShow = true;
      this.imgUrl = url;
    },
    shixue() {
      this.download();
    },
    // 获取分享产品id
    loadShare() {
      let _this = this;
      getShare({ goods_id: _this.detail.goods.goods_id }).then((res) => {
        console.log(res, "分享产品id");
        if (res.result) {
          _this.share_id = res.result.share_id;
        }
      });
    },
    getShow(e) {
      this.InviteShow = e;
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
  },
  created() {
    let _this = this
    if (document.body.clientWidth >= 1200) {
      window.location.href =
        "http://srgongkaow.com/camp/info/" + this.$route.query.id;
    }
    this.id = this.$route.query.id;
    this.share_id = this.$route.query.share_id;
    if(this.share_id){
      _this.InviteShow = true;
    }
    this.loadDetail();
  },
};
</script>
<style scoped>
.radio-teacher li img {
  border-radius: 50%;
}
.tx {
  border-radius: 50%;
  width: 50px !important;
  height: 50px;
}
.tanchuang-bg {
  display: block;
}
p {
  margin-bottom: 0;
}
ul li {
  margin-bottom: 0;
}
.xly-list {
  clear: both;
}
.xq-foot {
  padding-bottom: 20px;
}
.miaosha {
  font-size: 14px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
  display: inline-block;
  padding: 0;
}
.pintuan-but {
  padding: 0 1rem !important;
}
</style>