import request from '@/utils/request'
export function interests(parameter) {
    return request({
        url: '/api/index/interests',
        method: 'post',
        data: parameter
    })
}
export function getUserMembers(parameter) {
    return request({
        url: '/api/user/getUserMembers',
        method: 'post',
        data: parameter
    })
}
export function label(parameter) {
    return request({
        url: '/api/index/label',
        method: 'post',
        data: parameter
    })
}
export function buyMember(parameter) {
    return request({
        url: '/api/user/buyMember',
        method: 'post',
        data: parameter
    })
}