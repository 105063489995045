import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/index.vue' //首页
import schoolIndex from '@/views/school/index.vue' //网校
import courseGroupList from '@/views/school/courseGroupList.vue' //网校
import storeIndex from '@/views/store/index.vue' //商城
import login from '@/views/login/login.vue' //登录
import CourseDetail from '@/views/school/detail.vue' //训练营详情
import radio from '@/views/radio/index.vue' //电台首页
import radioDetail from '@/views/radio/detail.vue' //电台详情
import radioPlay from '@/views/radio/playRadio.vue' //电台详情
import radioList from '@/views/radio/list.vue' //电台分类列表
import user from '@/views/user/index.vue' //个人中心
import pintuan from '@/views/Pintuan/index.vue' //拼团
import pintuanGroup from '@/views/pintuanGroup/index.vue' //拼团
import schoolList from '@/views/school/list.vue' //网校列表
import courseDetail from '@/views/school/courseDetail.vue' //网校详情
import pay from '@/views/pay/index.vue' //购买
import addressList from '@/views/pay/addressList.vue' //收货地址
import paySuccess from '@/views/pay/success.vue' //支付成功
import pintuanSuccess from '@/views/pay/pintuanSuccess.vue' //拼团成功
import courseGroupDetail from '@/views/school/courseGroupDetail.vue' //课程包详情
import payLoading from '@/views/pay/payLoading.vue' //支付跳转
import passwordLogin from '@/views/login/passwordLogin.vue'
import addAddress from '@/views/pay/addAddress.vue'
import jump from '@/views/jump/jump.vue'
import register from '@/views/login/register.vue' //邀请注册
import fail from '@/views/pay/fail.vue' //邀请注册
import storeDetail from '@/views/store/detail.vue' //商城详情
import weixinLogin from '@/views/login/weixinLogin.vue'
import courseIndex from '@/views/school/courseIndex.vue'
import storeList from '@/views/store/list.vue'
import vip from '@/views/vip/vip.vue'
import information from '@/views/information/index.vue'
import mock from '@/views/mock/index.vue'
import mockSelect from '@/views/mock/select.vue'
import courseDownload from '@/views/school/downLoadDetail.vue'
Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [{ //首页
            path: '/',
            name: 'Index',
            component: Index,
            meta: {
                nav: 'index',
                title: '首页'
            }
        },
        { //网校
            path: '/school',
            name: 'schoolIndex',
            component: schoolIndex,
            meta: {
                title: '课程'
            },
        },
        { //网校
            path: '/courseGroupList',
            name: 'courseGroupList',
            component: courseGroupList,
            meta: {
                title: '课程包'
            },
        },
        { //公众号所用网校
            path: '/courseIndex',
            name: 'courseIndex',
            component: courseIndex,
            meta: {
                title: '课程'
            },
        },
        { //商城
            path: '/store',
            name: 'storeIndex',
            component: storeIndex,
            meta: {
                title: '商城'
            },
        },
        { //商城
            path: '/store/detail',
            name: 'storeDetail',
            component: storeDetail,
            meta: {
                title: '商城详情'
            },
        },
        { //商城
            path: '/store/list',
            name: 'storeList',
            component: storeList,
            meta: {
                title: '商城'
            },
        },
        { //网校
            path: '/school/courseDetail',
            name: 'courseDetail',
            component: courseDetail,
            meta: {
                title: '课程详情'
            },
        },
        { //网校列表
            path: '/school/list',
            name: 'schoolList',
            component: schoolList,
            meta: {
                title: '课程列表'
            },
        },
        { //登录
            path: '/login',
            name: 'login',
            component: login,
            meta: {
                title: '登录'
            },
        },
        { //邀请注册
            path: '/register',
            name: 'register',
            component: register,
            meta: {
                title: '邀请注册'
            },
        },
        { //电台首页
            path: '/radio',
            name: 'radio',
            component: radio,
            meta: {
                title: '电台'
            },
        },
        { //电台详情页
            path: '/radio/detail',
            name: 'radioDetail',
            component: radioDetail,
            meta: {
                title: '电台详情'
            },
        },
        { //电台详情页播放
            path: '/radio/detail/play',
            name: 'radioPlay',
            component: radioPlay,
            meta: {
                title: '电台详情'
            },
        },
        { //电台分类列表页
            path: '/radio/list',
            name: 'radioList',
            component: radioList,
            meta: {
                title: '电台详情'
            },
        },
        { //训练营详情
            path: '/school/detail',
            name: 'CourseDetail',
            component: CourseDetail,
            meta: {
                title: '训练营详情'
            }
        },
        { //个人中心
            path: '/user',
            name: 'user',
            component: user,
            meta: {
                title: '个人中心'
            }
        },
        { //查看拼团人数页面
            path: '/pintuan',
            name: 'pintuan',
            component: pintuan,
            meta: {
                title: '拼团'
            }
        },
        { //查看拼团人数页面
            path: '/pintuanGroup',
            name: 'pintuanGroup',
            component: pintuanGroup,
            meta: {
                title: '拼团'
            }
        },
        { //购买
            path: '/pay',
            name: 'pay',
            component: pay,
            meta: {
                title: '支付'
            }
        },
        { //购买
            path: '/payLoading',
            name: 'payLoading',
            component: payLoading,
            meta: {
                title: '支付确认中...'
            }
        },
        { //选择收货地址
            path: '/pay/addressList',
            name: 'addressList',
            component: addressList,
            meta: {
                title: '收货地址'
            }
        },
        { //添加收货地址
            path: '/pay/addAddress',
            name: 'addAddress',
            component: addAddress,
            meta: {
                title: '添加收货地址'
            }
        },
        { //支付成功
            path: '/pay/success',
            name: 'paySuccess',
            component: paySuccess,
            meta: {
                title: '支付成功'
            }
        },
        { //拼团成功
            path: '/pay/pintuanSuccess',
            name: 'pintuanSuccess',
            component: pintuanSuccess,
            meta: {
                title: '拼团成功'
            }
        },
        { //课程包
            path: '/school/courseGroupDetail',
            name: 'courseGroupDetail',
            component: courseGroupDetail,
            meta: {
                title: '课程包详情'
            }
        },
        { //密码登录
            path: '/passwordLogin',
            name: 'passwordLogin',
            component: passwordLogin,
            meta: {
                title: '课程包详情'
            }
        },
        { //APP跳转H5中间页
            path: '/jump',
            name: 'jump',
            component: jump,
            meta: {
                title: '分享跳转'
            }
        },
        { //支付失败
            path: '/pay/fail',
            name: 'fail',
            component: fail,
            meta: {
                title: '支付失败'
            }
        },
        { //微信登陆
            path: '/weixinLogin',
            name: 'weixinLogin',
            component: weixinLogin,
            meta: {
                weixinLogin: '微信登陆'
            }
        }, ,
        { //微信登陆
            path: '/vip',
            name: 'vip',
            component: vip,
            meta: {
                title: '成公社超级会员'
            }
        },
        { //微信登陆
            path: '/information',
            name: 'information',
            component: information,
            meta: {
                title: '资讯详情'
            }
        },
        { //模考报名
            path: '/mock',
            name: 'mock',
            component: mock,
            meta: {
                title: '模考报名'
            }
        },
        { //模考报名选项
            path: '/mockSelect',
            name: 'mockSelect',
            component: mockSelect,
            meta: {
                title: '模考报名'
            }
        },
        { //下载文件页面
            path: '/school/downLoadDetail',
            name: 'courseDownload',
            component: courseDownload,
            meta: {
                title: '文件下载'
            }
        }
    ]
})