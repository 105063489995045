<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">课程包</h1>
    </header>
    <div class="mui-content">
      <div
        class="wx-list"
        v-for="i in list"
        v-if="i.type == 1"
        @click="goCourseDetail(i)"
      >
        <p class="color-red" :style="'color:' + i.relation.subtitle_color">
          {{ i.relation.subtitle }}
        </p>
        <h4>{{ i.relation.name }}</h4>
        <!-- <p class="color-999">{{ i.start }}-{{ i.end }}</p> -->
        <div class="wx-listImg mt05 clearfix">
          <ul>
            <li class="mui-text-center" v-for="item in i.relation.teacher">
              <img style="border-radius: 50%;width:48px;height:48px" :src="item.avatar.url" />
              <p class="color-999">{{ item.name }}</p>
            </li>
          </ul>
        </div>
        <div class="wx-listPrice clearfix">
          <p class="mui-pull-left color-999">
            限售{{ i.sales_restriction }}人 | 已报名{{ i.sale_amount }}人
          </p>
          <h3 class="miaosha">{{ i.real_price }}</h3>
          <p class="mui-pull-right color-red f-18">￥{{ i.order_price }}</p>
        </div>

        <span class="wx-label" v-if="i.notend_seckill"
          ><img src="@/assets/img/wx-miaosha.png"
        /></span>
        <span class="wx-label" v-if="i.agreement_class == 1"
          ><img src="@/assets/img/wx-agreement.png"
        /></span>
        <span class="wx-label" v-if="i.relation.vip_free == 1"
          ><img src="@/assets/img/wx-free.png"
        /></span>
      </div>
    </div>
  </div>
</template>
<script>
var jweixin = require("/jweixin-module/index.js");
import KeFu from "@/components/kefu";
import Mui from "../../assets/js/mui.min.js";
import { coursePackage } from "@/api/course";
export default {
  name: "courseDetail",
  components: {
    KeFu,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        // 申论滑动
        var swiper = new Swiper(".shenlun", {
          slidesPerView: 3.8,
          spaceBetween: 30,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 800);
    });
  },
  methods: {
    loadDetail() {
      let _this = this;
      coursePackage({ course_package_id: _this.course_package_id }).then(
        (res) => {
          console.log(res, "详情");
          // _this.loadcomment_list();
          _this.list = res.result.relation_goods;
        }
      );
    },
    goCourseDetail(i) {
      console.log(i);
      if (i.type == 1) {
        window.location.href =
          "https://wap.srgongkaow.com/school/courseDetail?course_id=" +
          i.relation_id;
      }
      if (i.type == 3) {
        window.location.href =
          "https://wap.srgongkaow.com/school/detail?course_id=" +
          i.relation_id;
      }
    },
  },
  created() {
    this.course_package_id = this.$route.query.id;
    this.loadDetail();
  },
};
</script>
<style scoped>
.miaosha {
  float: right;
  font-size: 14px;
  padding-top: 6px;
  padding-left: 10px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
}
</style>