<template>
  <div class="hello">
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">成公社</h1>
    </header>
    <div class="mui-content">
      <!-- 课程提醒 -->
      <div class="kctx-box" v-if="tiXingList.length > 0">
        <h3 class="kctx-t">课程提醒</h3>
        <ul class="kctx-m">
          <li v-for="i in tiXingList">
            <h4>
              <em>{{
                i.type == 1 ? "课程" : i.type == 3 ? "电台" : "训练营"
              }}</em>
              {{ i.name }}
            </h4>
            <p class="color-999">{{ i.children_name }}</p>
            <p>
              <span v-for="item in i.teacher" class="color-999 tixing_box"
                ><img class="avatar" style="width:34px;height:34px;" :src="item.avatar.url" />
                {{ item.name }}</span
              >
            </p>
            <a @click="xuexi">去学习</a>
          </li>
        </ul>
      </div>
      <!-- banner -->
      <div class="swiper-container banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="i in bannerList">
            <img :src="i.image.url" @click="openBanner(i.jump, i.jump_id)" />
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
      <!-- 课程列表 -->
      <div class="warp">
        <div class="swiper-container kecheng-listT">
          <div class="swiper-wrapper">
            <div
              :class="
                indexType == index
                  ? 'swiper-slide swiper-slide-thumb-active'
                  : 'swiper-slide'
              "
              v-for="(i, index) in campTypeList"
              @click="getType(i.camp_type_id, index)"
            >
              <span>{{ i.name }}</span>
            </div>
          </div>
        </div>
        <div class="kecheng-list" v-for="i in campList" @click="goDetail(i.camp_id)">
     
            <a>
              <img class="kecheng-listImg" :src="i.image.url" />
              <h3>{{ i.name }}</h3>
              <!-- <p class="color-main">报名倒计时：1天12时50分</p> -->
              <p class="color-999">
                已报名{{ i.goods.sale_amount }}人 | 剩余席位{{
                  i.goods.surplus_seat
                }}人
              </p>
              <p class="color-red kecheng-listPrice">
                <font size="4" color="">{{ i.order_price }}</font>
              </p>
              <h3 class="miaosha" v-if="i.real_price">{{ i.real_price }}</h3>
            </a>
        </div>
      </div>
    </div>
    <Bottom :act="1" style="padding-bottom: 50px"></Bottom>
  </div>
</template>

<script>
import storage from "@/utils/storage";
import Mui from "../assets/js/mui.min.js";
import Swiper from "../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
import { loadBanner, classes_remind } from "@/api/common";
import { type, campList } from "@/api/camp";
export default {
  name: "HelloWorld",
  props: {},
  components: {
    Bottom,
  },
  data() {
    return {
      bannerList: [],
      tiXingList: [],
      campTypeList: [],
      camp_type_id: null,
      campList: [],
      indexType: 0,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
    this.$nextTick(() => {
      setTimeout(() => {
        this.swiper = new Swiper(".banner", {
          // eslint-disable-line no-unused-vars
          pagination: {
            el: ".swiper-pagination",
          },
          loop: true,
          autoplay: 2000,
          autoplay: {
            disableOnInteraction: false,
          },
        });
        // 课程列表
        this.galleryThumbs = new Swiper(".kecheng-listT", {
          spaceBetween: 0,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });
        this.galleryTop = new Swiper(".kecheng-listM", {
          spaceBetween: 10,
          thumbs: {
            swiper: this.galleryThumbs,
          },
        });
      }, 300);
    });
  },
  methods: {
    loadBanner: function () {
      let _this = this;
      loadBanner({}).then((res) => {
        console.log(res, "banner");
        _this.bannerList = res.result;
      });
    },
    loadclasses_remind() {
      let _this = this;
      classes_remind({}).then((res) => {
        console.log(res,'-----课程提醒')
        _this.tiXingList = res.result;
      });
    },
    loadCamptype() {
      let _this = this;
      type({}).then((res) => {
        _this.campTypeList = res.result;
        // _this.camp_type_id = res.result[0].camp_type_id
        _this.loadCampList(res.result[0].camp_type_id);
      });
    },
    loadCampList(id) {
      let _this = this;
      campList({
        camp_type_id: id,
        order_by: "default",
      }).then((res) => {
        console.log(res, "------训练营");
        var data = res.result.items;
        // console.log(data,'ddddd');
        // if (storage.getItem("userInfo") == null) {
        //   var url = _this.GetUrlRelativePath();
        //   storage.setItem("LOGIN_SKIP", url);
        //   _this.$router.push({ path: "/login?action=login" });
        // }
        var userInfo = storage.getItem("userInfo");
        console.log(userInfo,'个人信息')
        //   课程 1.划线价格 没活动的时候 展示原价   有活动的时候展示会员VIP6的价格  2.展示价格有活动的时候展示活动价  没活动的时候展示 自己用户当前等级的价格
        data.map(function (n) {
          if (
            n.goods.notend_pintuan ||
            n.goods.notend_seckill
          ) {
            if (n.goods.notend_seckill) {
              n.order_price =
                n.goods.notend_seckill.goods.order_price;
              n.real_price = n.goods.real_price;
            }
            if (n.goods.notend_pintuan) {
              n.order_price =
                n.goods.notend_pintuan.goods.order_price;
                n.real_price = n.goods.real_price;
            }
          } else {
            if (n.goods.vip_price.length > 0) {
              // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
              if (userInfo && userInfo.now_user_member) {
                n.goods.vip_price.map(function (s) {
                  if (
                    s.user_member_config_id ==
                    userInfo.now_user_member.user_member_config_id
                  ) {
                    n.order_price = s.price;
                  }
                });
                n.real_price = n.goods.real_price;
              } else {
                n.order_price = n.goods.order_price;
              }
            } else {
              n.order_price = n.goods.order_price;
            }
          }
           if(n.order_price == n.real_price){
            n.real_price = ''
          }
        });
          _this.campList = data;
      });
    },
    getType(id, index) {
      this.indexType = index;
      this.loadCampList(id);
    },
    xuexi() {
      Mui.alert("请到APP端进行学习", "温馨提示", function () {
        console.log("点击确定");
      });
    },
    openBanner(jump, jump_id) {
      // 跳转类型 1课程 2课程包 3电台 4训练营
      if (jump == 1) {
        this.$router.push({
          path: "/school/courseDetail",
          query: { course_id: jump_id },
        });
      } else if (jump == 2) {
        this.$router.push({
          path: "/school/courseGroupDetail",
          query: { course_package_id: jump_id },
        });
      } else if (jump == 3) {
        this.$router.push({ path: "/radio/detail", query: { id: jump_id } });
      } else {
        this.$router.push({ path: "/school/detail", query: { id: jump_id } });
      }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
    goDetail(id){
         window.location.href="https://wap.srgongkaow.com/school/detail?id=" + id
    }
  },
  created() {
    // 状态栏背景
    if (document.body.clientWidth >= 1200) {
      window.location.href = "http://srgongkaow.com/";
    }
    this.loadBanner();
    this.loadclasses_remind();
    this.loadCamptype();
    console.log(document.body.clientWidth);
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../assets/css/swiper.min.css";
/* .kecheng-list:last-child {
  margin-bottom: 150px;
} */
.tixing_box {
  margin-right: 10px;
}
.kecheng-listT div > div {
  width: 21% !important;
  margin-right: 10px !important;
}
.kecheng-listT div > div:first-child {
  width: 80px !important;
}
.miaosha {
  font-size: 14px;
  padding-top: 6px;
  padding-left: 10px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
  position: absolute;
  right: 40px;
  bottom: 0.6rem;
}
.kecheng-listPrice {
  right: 0;
  margin: 0;
}
</style>
