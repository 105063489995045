<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">{{ name }}</h1>
    </header>
    <div class="mui-content">
      <div class="huodongT">
        <span
          :class="tabIndex == 0 ? 'active' : ''"
          @click="getTab('default', 0)"
          >综合排序</span
        >
        <span
          :class="tabIndex == 1 ? 'active' : ''"
          @click="getTab('playback_volume', 1)"
          >播放更多</span
        >
        <span
          :class="tabIndex == 2 ? 'active' : ''"
          @click="getTab('created_at', 2)"
          >最近更新</span
        >
      </div>
      <div class="border-top">
        <div class="radio-fl clearfix" v-for="i in list">
          <router-link
            :to="{ path: '/radio/detail', query: { id: i.radio_id } }"
          >
            <div class="radio-img mui-col-xs-4 mui-pull-left pos-rel">
              <img :src="i.image.url" />
              <span
                ><img src="@/assets/img/radio-icon2.png" />播放{{
                  i.playback_volume
                }}次</span
              >
              <em class="radio-st2" v-if="i.audition == 1"
                ><img src="@/assets/img/radio-icon1.png" /> 试听</em
              >
            </div>
            <div class="radio-tex mui-col-xs-8 mui-pull-left">
              <h4>{{ i.name }}</h4>
              <h5 class="color-999 mt05">
                {{ i.start }} - {{ i.end }} {{ i.period }}课时
              </h5>
              <!-- <h5 class="color-999 mt05">江洋 | 果冻 | 英子</h5> -->
              <h3 class="color-red mt1">￥{{ i.goods.order_price }}</h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Radiogetlist } from "@/api/radio";
export default {
  name: "list",
  data() {
    return {
      radio_category_id: null,
      order_by: "default",
      list: [],
      tabIndex: 0,
      name: null,
    };
  },

  methods: {
    loadList() {
      let _this = this;
      Radiogetlist({
        radio_category_id: _this.radio_category_id,
        order_by: _this.order_by,
      }).then((res) => {
        console.log(res, "分类");
        var data = res.result.items;
        data.map(function (n) {
          n.start = n.start_at.substring(0, 10);
          n.end = n.end_at.substring(0, 10);
        });
        _this.list = data;
      });
    },
    getTab(order_by, index) {
      this.order_by = order_by;
      this.tabIndex = index;
      this.loadList();
    },
  },
  created() {
    this.radio_category_id = this.$route.query.radio_category_id;
    this.name = this.$route.query.name;
    this.loadList();
  },
};
</script>