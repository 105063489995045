<template>
  <div>
    <div class="pindan-box">
      <div class="pindan-t clearfix">
        <span class="mui-pull-left">{{TuanList.length}}人在拼单，可直接参与</span>
        <span
          class="mui-pull-right color-999 f-14 tanchuang-submit"
          @click="PinTuanMore"
          >查看更多&gt;&gt;</span
        >
      </div>
      <!-- 查看更多弹窗 -->
      <div
        class="tanchuang-bg"
        :style="show ? 'display:block' : 'display:none'"
      >
        <div class="tanchuang-box">
          <h3 class="mui-text-center f-18">可参与的拼单</h3>
          <div class="pindan-m border-top mt05">
            <ul>
              <li v-for="(i,index) in TuanList">
                <p><img class="tx" :src="i.chief.head_portrait.url" /></p>
                <p>{{i.chief.nickname}}</p>
                <p class="mui-text-right">
                  <span>还差{{3 - i.pintuan_user_count}}人拼成</span><br />
                  <span class="color-666">剩余
                      <a-statistic-countdown class="daojishi"
                :value="i.pintuan.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
                  </span>
                </p>
                <p class="mui-text-right"><a>去拼单</a></p>
              </li>
            </ul>
          </div>
        </div>
        <p class="tanchuang-close mui-text-center" @click="close">
          <img src="@/assets/img/tc-close.png" />
        </p>
      </div>
      <div class="pindan-m">
        <ul>
          <li v-for="(i,index) in TuanList" v-if="index < 4">
            <p><img class="tx" :src="i.chief.head_portrait.url" /></p>
            <p>{{i.chief.nickname}}</p>
            <p class="mui-text-right">
              <span>还差{{i.pintuan.number - i.pintuan_user_count}}人拼成</span><br />
              <span class="color-666">剩余
                   <a-statistic-countdown class="daojishi"
                :value="i.pintuan.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
              </span>
            </p>
            <p class="mui-text-right" @click="goPinDan(i)"><a>去拼单</a></p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { PinTuanList } from "@/api/common";
export default {
  name: "bottom",
  props: {
    pintuan_id: Number,
    goods:Object
  },
  data() {
    return {
      show: false,
      TuanList:[]
    };
  },
  methods: {
    PinTuanMore() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    loadTuanList() {
      let _this = this;
      PinTuanList({ pintuan_id: _this.pintuan_id }).then((res) => {
        console.log(res, "团购");
        _this.TuanList = res.result
      });
    },
    goPinDan(i){
      this.$router.push({path:'/pintuan',query:{goods:JSON.stringify(this.goods),pintuan:JSON.stringify(i),type:1}})
    }
  },
  created() {
    this.loadTuanList();
  },
};
</script>
<style scoped>
.tanchuang-bg {
  display: block;
}
>>> .ant-statistic {
  display: inline-block;
}

>>> .ant-statistic-content {
  display: inline-block;
  font-size: 12px;
}

>>> .ant-statistic-content-value {
  font-size: 12px;
  color: #999;
}
.tx{
    border-radius: 50%;
}
</style>