import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import theConfirm from '@/components/SrMessage/index'
import Vant from 'vant';
import md5 from 'js-md5';
import LyTab from 'ly-tab';
import moment from "moment"
import Meta from "vue-meta"
import 'vant/lib/index.css';
Vue.config.productionTip = false
Vue.prototype.$srmsg = theConfirm;
Vue.use(Antd);
Vue.use(Vant);
Vue.use(LyTab);
Vue.use(Meta)
Vue.prototype.$md5 = md5;
Vue.prototype.$moment = moment;
new Vue({
  render: h => h(App),
  router
}).$mount('#app');