<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">商品详情</h1>
    </header>
    <img style="width: 100%; margin-top: 24px" :src="detail.image.url" alt="" />
    <div class="container">
      <div class="s_title">{{ detail.name }}</div>
      <span class="s_ms" :style="'color:' + detail.subtitle_color">{{
        detail.subtitle
      }}</span>
      <div class="s_box">
        <div>已报名：{{ detail.goods.sale_amount }}人</div>
        <!-- <span>有效期至：2021-3-1</span> -->
      </div>
    </div>
    <div class="jiange"></div>
    <!-- 拼团 -->
    <div class="bg-fff mt1" v-if="detail.goods.notend_pintuan">
      <PinTuan
        :pintuan_id="detail.goods.notend_pintuan.pintuan_id"
        :goods="detail.goods"
      ></PinTuan>
    </div>
    <div class="mui-slider kecheng-tab">
      <div
        id="sliderSegmentedControl"
        class="
          mui-slider-indicator
          mui-segmented-control
          mui-segmented-control-inverted
        "
      >
        <a class="mui-control-item mui-active" @click="getTab(0)"> 商品介绍 </a>
        <a class="mui-control-item" @click="getTab(1)"> 商品评价 </a>
        <a class="mui-control-item" @click="getTab(2)" v-if="detail.type == 2"> 章节目录 </a>
      </div>
    </div>
    <div class="kec_content">
      <div class="container">
        <div v-if="tabIndex == 0">
          <div v-html="imgWH(detail.introduce)"></div>
        </div>
        <div v-if="tabIndex == 1"></div>
        <div v-if="tabIndex == 2">
            <div class="downloadList">
              <ul>
                <li v-for="i in detail.file">
                  <p @click="downDetail(i.wares_file_id)">{{ i.file_name }}</p>
                  <img @click="downLoadFile(i.wares_file_id)" src="@/assets/img/xiazai.png" alt="">
                  </li>
              </ul>
            </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">￥{{ detail.order_price }}</div>
      <div class="right" @click="showPopup">立即购买</div>
    </div>
    <van-popup v-model="show" position="bottom">
      <div class="carbox">
        <div class="carbox_img">
          <img :src="detail.image.url" alt="" />
          <p>{{ detail.jisuan }}</p>
        </div>
        <div class="car_boxSku">
          <p>规格</p>
          <div
            v-for="(i, index) in detail.sku"
            :class="skuIndex == index ? 'sku_btnHover' : 'sku_btn'"
            @click="getSku(i, index)"
          >
            {{ i.name }}
          </div>
        </div>
        <div class="car_num">
          <p>购买数量</p>
          <van-stepper
            v-model="num"
            button-size="20"
            disable-input
            class="car_num1"
            @change="numChange"
          />
        </div>
        <div class="payBtn" @click="goPay">立即购买</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import Mui from "../../assets/js/mui.min.js";
import { detail } from "@/api/store";
import { commentLike,waresDownload } from "@/api/common";
import PinTuan from "@/components/PinTuan";
export default {
  name: "bottom",
  components: {
    PinTuan,
  },
  data() {
    return {
      tabIndex: 0,
      sku_id: "",
      detail: {},
      sales_restriction: 0,
      show: false,
      skuIndex: 0,
      num: 1,
      sku_id: null,
    };
  },
  beforeCreate() {
    document.querySelector("body").setAttribute("style", "background:#fff");
  },
  methods: {
    getTab(index) {
      this.tabIndex = index;
    },
    showPopup() {
      this.show = true;
    },
    numChange(e) {
      console.log(e, "---");
      this.num = e;
      this.detail.jisuan = parseFloat(e * this.detail.order_price);
    },
    getSku(i, index) {
      console.log(i);
      console.log(index);
      this.detail.jisuan = i.real_price * this.num;
      this.skuIndex = index;
    },
    imgWH(string) {
      //正则替换图片宽高
      if (string) {
        string = string.replace(/ height="\d+"/g, ' height="auto"');
        string = string.replace(/ width="\d+"/g, ' width="100%"');
        return string;
      } else {
        return "";
      }
    },

    downDetail(wares_file_id) {
      waresDownload({ wares_file_id: wares_file_id }).then((res) => {
        if (res.status == 200) {
          window.location.href = res.result;
        } else {
          Mui.alert(res.msg, "温馨提示", function () {});
        }
      });
    },
    downLoadFile(wares_file_id) {
      let _this = this;
      waresDownload({ wares_file_id: wares_file_id }).then((res) => {
        console.log(res, "下载地址");
        if (res.status == 200) {
          if (_this.isWeiXin()) {
            Mui.alert("请点击右上角默认浏览器打开进行下载", "温馨提示", function () {});
          } else {
          // var url = res.result;
          // var url2 = url.replace('http','https');
          // var iframe = document.createElement('iframe');
          // iframe.style.display = 'none';
          // iframe.src = "javascript: '<script>location.href=\"" + url2 + "\"<\/script>'";
          // document.getElementsByTagName('body')[0].appendChild(iframe);
          window.location.href = res.result;
          }
   
        } else {
          Mui.alert(res.msg, "温馨提示", function () {});
        }
      });
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },



    goPay() {
      var obj = JSON.parse(JSON.stringify(this.detail.goods));
      console.log(obj);
      obj.order_price = this.detail.jisuan;
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(obj),
          type: 1,
          isPin: false,
          sku_id: this.sku_id,
          num: this.num,
        },
      });
    },
    loadDetail() {
      let _this = this;
      detail({
        wares_id: _this.$route.query.id,
      }).then((res) => {
        console.log(res, "详情");
        _this.detail = res.result;
        _this.sku_id =
          res.result.sku.length > 0 ? res.result.sku[0].sku_id : "";
        _this.sales_restriction =
          res.result.sku.length > 0 ? res.result.sku[0].sales_restriction : "";
        var userInfo = storage.getItem("userInfo");
        if (_this.detail.sku.length > 0) {
          if (userInfo) {
            if (userInfo.now_user_member) {
              _this.detail.sku[0].goods_sku_price.map(function (s) {
                if (
                  s.user_member_config_id ==
                  userInfo.now_user_member.user_member_config_id
                ) {
                  _this.detail.order_price = s.real_price;
                  _this.detail.jisuan = s.real_price;
                }
              });
            } else {
              _this.detail.order_price =
                _this.detail.sku[0].goods_sku_price[5].real_price;
              _this.detail.jisuan =
                _this.detail.sku[0].goods_sku_price[5].real_price;
            }
          } else {
            _this.detail.order_price =
              _this.detail.sku[0].goods_sku_price[5].real_price;
            _this.detail.jisuan =
              _this.detail.sku[0].goods_sku_price[5].real_price;
          }
        } else {
          console.log(1111);
          _this.detail.order_price = _this.detail.goods.order_price;
          _this.detail.jisuan = _this.detail.goods.order_price;
        }
      });
    },
  },
  created() {
    this.loadDetail();
  },
};
</script>
<style scoped>
.container {
  padding: 0 15px;
}
.payBtn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #3561c7;
  color: #fff;
  text-align: center;
  border-radius: 40px;
}
.s_title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 5px;
}
.s_ms {
  color: #666666;
  display: block;
}
.s_box {
  border-top: 1px solid #f0f0f0;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
}
.s_box div {
  display: inline-block;
  color: #999999;
}
.s_box span {
  float: right;
  color: #e6a3c6;
}
.jiange {
  height: 10px;
  width: 100%;
  background: #fafafa;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  line-height: 60px;
  background: #fff;
  width: 100%;
  padding: 0 15px;
}
.bottom .left {
  color: red;
  font-size: 16px;
  display: inline-block;
}
.bottom .right {
  background: #3561c7;
  float: right;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  border-radius: 50px;
  color: #fff;
  margin-top: 12px;
}
.carbox {
  padding: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  background: #fff;
}
.carbox_img {
  border-bottom: 1px solid #ececec;
  overflow: hidden;
  padding-bottom: 14px;
}
.carbox_img img {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-right: 20px;
  float: left;
}
.carbox_img p {
  display: inline-block;
  font-size: 18px;
  color: red;
  float: left;
}
.sku_btn {
  background: #ececec;
  float: left;
  padding: 10px;
}
.sku_btnHover {
  background: #ececec;
  border: 1px solid #3561c7;
  color: #3561c7;
  float: left;
  padding: 6px 10px;
}
.car_num {
  clear: both;
  margin-top: 20px;
  padding-bottom: 20px;
}
.car_num p {
  display: inline-block;
}
.car_num1 {
  width: 100px;
  float: right;
}
.car_boxSku {
  padding-bottom: 20px;
  border-bottom: 1px solid #ececec;
  overflow: hidden;
}
>>> .van-stepper__minus {
  display: inline-block;
}
>>> .van-stepper__input {
  width: 40%;
  margin-bottom: 0;
}
.downloadList{
  padding-bottom: 50px;
}
.downloadList ul li p{
  float: left;
  width: 80%;
}
.downloadList ul li img{
  float: right;
  width: 26px;
}
.downloadList ul li{
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-top: 20px;
  overflow: hidden;
}
</style>