<template>
    <div>
           <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">{{ name }}</h1>
    </header>
          <div class="radio-list bg-fff">
        <!-- <h3 class="radio-listT">课程推荐</h3> -->
        <ul class="clearfix">
          <li class="mui-col-xs-6 mui-pull-left" v-for="i in lists">
            <router-link
              :to="{ path: '/store/detail', query: { id: i.wares_id } }"
            >
              <a>
                <p class="pos-rel">
                  <img :src="i.image.url" />
                  <span class="radio-st" v-if="i.audition == 1"
                    ><img src="@/assets/img/radio-icon1.png" /> 试听</span
                  >
                </p>
                <h3>{{ i.name }}</h3>
                <p class="color-666" style="font-size: 12px">
                  {{ i.goods.sale_amount }}人付款
                </p>
              </a>
              <div class="prices">
                <p>￥{{i.order_price}}</p>
                <span>￥{{i.real_price}}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import storage from "@/utils/storage";
import Mui from "../../assets/js/mui.min.js";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
import { banner, category, list } from "@/api/store";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      bannerList: [],
      iconList: [],
      list: [],
      wares_category_id: null,
      lists: [],
      name:''
    };
  },
  methods: {
    recommendList() {
      let _this = this;
      list({ wares_category_id: _this.$route.query.id }).then((res) => {
        console.log(res, "商城列表");
        var data = res.result.items;
        var userInfo = storage.getItem("userInfo");
        console.log(userInfo,'用户')
        data.map(function (n) {
          if (n.goods.notend_pintuan || n.goods.notend_seckill) {
            if (n.goods.notend_seckill) {
              n.order_price = n.goods.notend_seckill.goods.order_price;
            }
            if (n.goods.notend_pintuan) {
              n.order_price = n.goods.notend_pintuan.goods.order_price;
            }
          } else {
            if (n.sku.length > 0) {
              // 商品的sdk对应用户会员价  如果没有会员则显示vip1的价格
              if(userInfo){
                 if (userInfo.now_user_member) {
                n.sku[0].goods_sku_price.map(function (s) {
                  if (
                    s.user_member_config_id ==
                    userInfo.now_user_member.user_member_config_id
                  ) {
                    n.order_price = s.real_price;
                  }
                });
              } else {
                n.order_price = n.sku[0].goods_sku_price[5].real_price;
                n.real_price = n.goods.real_price;
              }
              }else{
                n.order_price = n.goods.order_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              n.order_price = n.goods.order_price;
              n.real_price = n.goods.real_price;
            }
          }
        });
        _this.lists = data;
        console.log(_this.lists,'-----lists')
      });
    },

  },
  created() {
      this.name = this.$route.query.name
      console.log(this.$route.query)
      this.recommendList()
  },
};
</script>
<style scoped>
/* .mui-pull-left:last-child {
  margin-bottom: 30px;
} */
.jiange {
  height: 10px;
  width: 100%;
  background: #fafafa;
}
.pos-rel img {
  height: 160px;
}
.prices p{
  color: red;
  float: left;
}
.prices span{
  float: right;
  color: #7c7c7c;
  text-decoration:line-through;
}
.radio-list ul li h3{
  height: 40px;
}
</style>