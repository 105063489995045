<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-closeempty"
        style="font-size: 46px"
      ></a>
    </header>
    <div class="mui-content">
      <div class="warp login">
        <h3 class="mui-text-center">
          <img class="login-logo" src="@/assets/img/login-img1.jpg" />
        </h3>
        <ul>
          <li>
            <input
              placeholder="请输入手机号码"
              v-model="form.phone"
              type="text"
              id=""
              value=""
            />
          </li>
          <li style="position: relative">
            <input
              placeholder="请输入密码"
              v-model="form.password"
              type="password"
              id=""
              value=""
            />
          </li>
        </ul>
        <SrButton style="width: 100%" @click="login" :loading="login_loading">
          登录
        </SrButton>
        <p class="mui-text-right mt05">
          <div style="display:inline-block;" @click="goPassword">手机登录</div>
          <a class="color-999" href="" style="float:right">登录遇到问题？</a>
        </p>
      </div>
      <!-- <div class="warp login-other">
        <h3 class="mui-text-center color-999"><span>其他登录方式</span></h3>
        <div class="clearfix mt1">
          <div class="mui-col-xs-6 mui-pull-left mui-text-center">
            <img src="@/assets/img/login-img2.jpg" />
            <p class="color-999">QQ</p>
          </div>
          <div class="mui-col-xs-6 mui-pull-right mui-text-center">
            <img src="@/assets/img/login-img3.jpg" />
            <p class="color-999">微信</p>
          </div>
        </div>
        <p class="mui-text-center color-999 mt1">
          登录及表示同意<a class="color-main" href="">《用户协议》</a>及<a
            class="color-main"
            href=""
            >《隐私政策》</a
          >
        </p>
      </div> -->
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { sendMessage, login, getUserInfo } from "@/api/common";
export default {
  name: "bottom",
  components: {},
  components: {
    SrButton,
  },
  data() {
    return {
      login_type: 1, //1验证码登录 2密码登录
      login_loading: false,
      sms_loading: false,
      form: {},
      sms_time: 0,
      showQuestion: false,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  methods: {
    sendMessage() {
      var _self = this;
      if (_self.sms_loading) {
        return false;
      }
      _self.sms_loading = true;
      sendMessage({ ..._self.form, scenario: "LoginCode" }).then((res) => {
        if (res.status === 200) {
          _self.sms_loading = false;
          _self.sms_time = 60;
          var timer = setInterval(function () {
            _self.sms_time--;
            storage.setItem("sms_time", _self.sms_time);
            if (_self.sms_time <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        } else {
          _self.$message.warning(res.msg);
          _self.sms_loading = false;
        }
      });
    },

    login() {
      var _self = this;
      //获取跳转页
      var url = storage.getItem("LOGIN_SKIP");
      if (!url || url === "/login") {
        url = "/";
      }
      if (_self.login_loading) {
        return false;
      }
      _self.login_loading = true;
      //验证码登录
      login(this.form).then((res) => {
        if (res.status === 200) {
       _self.login_loading = false;
          _self.$message.success("登录成功");
          storage.setItem("ACCESS_TOKEN", res.result);
          getUserInfo().then((res) => {
          storage.setItem("userInfo", res.result);
          });
          _self.$router.push(url);
        } else {
          _self.$message.warning(res.msg);
          _self.login_loading = false;
        }
      });
      //   if (_self.login_type === 1) {

      //   } else {
      //     login(this.form).then((res) => {
      //       if (res.status === 200) {
      //         _self.login_loading = false;
      //         _self.$message.success("登录成功");
      //         storage.setItem("ACCESS_TOKEN", res.result);
      //         _self.$router.push(url);
      //       } else {
      //         _self.$message.warning(res.msg);
      //         _self.login_loading = false;
      //       }
      //     });
      //   }
    },
      goPassword(){
      this.$router.push('/login')
    },
  },
  created() {},
};
</script>
<style scoped>
.login-other {
  position: absolute;
  width: 97%;
  bottom: 1rem;
}
</style>