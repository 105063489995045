<template>
    <div id="box">
        <a-modal
                style="border-radius:"
                v-model="isShow"
                 :width="300"
                :centered="true"
                 :closable="false"
                 :maskClosable="false"
                 wrapClassName="sr-dialog"
                 :bodyStyle="{padding:0}"
                 :footer="false">
            <div>
                <div class="">
                    <div class="txtx">
                        <div class="txtx_wen">
                            <h6>{{text.title}}</h6>
                            <p style="text-align: center">{{text.content}}</p>
                        </div>
                        <div v-if="type==='confirm'"
                             class="txtx_zhuang">
                            <a @click="okHandle">确定</a>
                            <a @click="cancelHandle"
                               class="guanbi guanbi_a">取消</a>
                        </div>
                        <div v-if="type==='info'"
                             class="txtx_zhuang">
                            <a @click="okHandle"
                               style="width: 100%">确定</a>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isShow: true,
                text: {},
                type: 'info'
            }
        },
        methods: {
            cancelHandle() {
            },
            okHandle() {
            }
        }
    }
</script>
<style scoped>
>>> .ant-modal-content{
    background-color: transparent!important;
    box-shadow: none;
}
</style>
