<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">商城</h1>
    </header>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <!-- 电台banner -->
        <div class="swiper-container banner inside-ban">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="i in bannerList"
              @click="goBanner(i.jump_id, i.jump)"
            >
              <img :src="i.image.url" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <!-- 网校导航 -->
        <div class="swiper-container mui-text-center wx-menu">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in iconList">
              <router-link
                :to="{
                  path: '/store/list',
                  query: {
                    id: i.wares_category_id,
                    name: i.name,
                  },
                }"
              >
                <a>
                  <img style="margin-bottom: 10px" :src="i.icon.url" />
                  <p>{{ i.name }}</p>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="jiange"></div>
      <div class="radio-list bg-fff">
        <!-- <h3 class="radio-listT">课程推荐</h3> -->
        <ul class="clearfix">
          <li class="mui-col-xs-6 mui-pull-left" v-for="i in lists">
            <router-link
              :to="{ path: '/store/detail', query: { id: i.wares_id } }"
            >
              <a>
                <p class="pos-rel">
                  <img :src="i.image.url" />
                  <span class="radio-st" v-if="i.audition == 1"
                    ><img src="@/assets/img/radio-icon1.png" /> 试听</span
                  >
                </p>
                <h3>{{ i.name }}</h3>
                <p class="color-666" style="font-size: 12px">
                  {{ i.goods.sale_amount }}人付款
                </p>
              </a>
              <div class="prices">
                <p>￥{{ i.order_price }}</p>
                <span>￥{{ i.real_price }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <Bottom :act="5" style="padding-bottom: 50px"></Bottom>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import Mui from "../../assets/js/mui.min.js";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
import { banner, category, list } from "@/api/store";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      bannerList: [],
      iconList: [],
      list: [],
      wares_category_id: null,
      lists: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.swiperInit();
    }, 100);
  },
  methods: {
    swiperInit() {
      this.$nextTick(() => {
        setTimeout(() => {
          var swiper = new Swiper(".inside-ban", {
            pagination: {
              el: ".swiper-pagination",
            },
            autoplay: 2000,
            autoplay: {
              disableOnInteraction: false,
            },
          });
          // 电台导航
          var swiper = new Swiper(".wx-menu", {
            slidesPerView: 4,
          });
        }, 200);
      });
    },
    loadBanner() {
      let _this = this;
      banner({}).then((res) => {
        console.log(res, "-=-----商城轮播");
        _this.bannerList = res.result;
      });
    },
    getlist() {
      let _this = this;
      getlist({}).then((res) => {
        _this.iconList = res.result;
      });
    },
    recommendList() {
      let _this = this;
      list({ wares_category_id: _this.wares_category_id }).then((res) => {
        console.log(res, "商城列表");
        var data = res.result.items;
        var userInfo = storage.getItem("userInfo");
        console.log(userInfo, "用户");
        data.map(function (n) {
          if (n.goods.notend_pintuan || n.goods.notend_seckill) {
            if (n.goods.notend_seckill) {
              n.order_price = n.goods.notend_seckill.goods.order_price;
            }
            if (n.goods.notend_pintuan) {
              n.order_price = n.goods.notend_pintuan.goods.order_price;
            }
          } else {
            if (n.sku.length > 0) {
              // 商品的sdk对应用户会员价  如果没有会员则显示vip1的价格
              if (userInfo) {
                if (userInfo.now_user_member) {
                  n.sku[0].goods_sku_price.map(function (s) {
                    if (
                      s.user_member_config_id ==
                      userInfo.now_user_member.user_member_config_id
                    ) {
                      n.order_price = s.real_price;
                    }
                  });
                } else {
                  n.order_price = n.sku[0].goods_sku_price[5].real_price;
                  n.real_price = n.goods.real_price;
                }
              } else {
                n.order_price = n.sku[0].goods_sku_price[5].real_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              n.order_price = n.goods.order_price;
              n.real_price = n.goods.real_price;
            }
          }
        });
        _this.lists = data;
        console.log(_this.lists, "-----lists");
      });
    },
    goBanner(jump_id, jump) {
      if (jump == 1) {
        this.$router.push({
          path: "/school/courseDetail",
          query: { course_id: jump_id },
        });
      } else if (jump == 2) {
        this.$router.push({
          path: "/school/courseGroupDetail",
          query: { course_package_id: jump_id },
        });
      } else if (jump == 3) {
        this.$router.push({ path: "/radio/detail", query: { id: jump_id } });
      } else if (jump == 4) {
        this.$router.push({ path: "/school/detail", query: { id: jump_id } });
      } else {
         this.$router.push({ path: "/store/detail", query: { id: jump_id } });
      }
    },
    loadCategory() {
      let _this = this;
      category({}).then((res) => {
        console.log(res, "分类");
        _this.iconList = res.result;
        _this.wares_category_id = res.result[0].wares_category_id;
      });
    },
  },
  created() {
    this.loadBanner();
    this.loadCategory();
    // this.getlist();
    this.recommendList();
  },
};
</script>
<style scoped>
/* .mui-pull-left:last-child {
  margin-bottom: 30px;
} */
.jiange {
  height: 10px;
  width: 100%;
  background: #fafafa;
}
.pos-rel img {
  height: 160px;
}
.prices p {
  color: red;
  float: left;
}
.prices span {
  float: right;
  color: #7c7c7c;
  text-decoration: line-through;
}
.radio-list ul li h3{
  height: 40px;
}
</style>