<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">资讯详情</h1>
    </header>
    <div class="new_box">
      <div class="title">{{ detail.title }}</div>
      <div class="time">发布时间{{ detail.created_at }}</div>
      <div class="content" v-html="detail.content"></div>
    </div>
    <div class="common">
        <div class="common_title">已有{{detail.comment.length}}次留言</div>
       <dl class="pinglun clearfix" v-for="i in detail.comment">
                <dt class="mui-pull-left">
                  <img class="dis-inb tx" :src="i.user.head_portrait.url" />
                </dt>
                <dd class="mui-pull-right pos-rel">
                  <p>
                    {{ i.user.nickname }}
                    <img
                      class="dis-inb jibie"
                      v-if="i.user.now_user_member"
                      :src="
                        i.user.now_user_member.user_member_config_id == 1
                          ? '/img/jibie.png'
                          : i.user.now_user_member.user_member_config_id == 2
                          ? '/img/jibie2.png'
                          : i.user.now_user_member.user_member_config_id == 3
                          ? '/img/jibie3.png'
                          : i.user.now_user_member.user_member_config_id == 4
                          ? '/img/jibie4.png'
                          : i.user.now_user_member.user_member_config_id == 5
                          ? '/img/jibie5.png'
                          : '/img/jibie6.png'
                      "
                    />
                  </p>
                  <p class="color-666">
                    {{ i.content }}
                  </p>
                  <p class="clearfix">
                    <span v-for="item in i.image">
                      <van-cell is-link @click="showPopup(item.url)"
                        ><img :src="item.url"
                      /></van-cell>
                    </span>
                  </p>
                  <p
                    class="pinglun-zan color-999"
                    @click="clickLike(i.camp_diary_id, i.is_like)"
                  >
                    <img
                      src="@/assets/img/praise.png"
                      v-if="i.is_like == 0"
                    /><img src="@/assets/img/praise-hover.png" v-else />{{
                      i.likes
                    }}
                  </p>
                </dd>
              </dl>
    </div>
  </div>
</template>   
<script>
import { information } from "@/api/common";
export default {
  name: "fail",
  data() {
    return {
      detail: {},
      information_id:null
    };
  },
  methods: {
    loadDetail() {
      let _this = this;
      information({ information_id: _this.information_id}).then((res) => {
        console.log(res, "资讯");
        _this.detail = res.result;
      });
    },
  },
  created() {
      console.log(this.$route.query)
      this.information_id = this.$route.query.information_id
    this.loadDetail();
  },
};
</script>
<style scoped>
.new_box {
  padding: 50px 15px 0 15px;
}
.title{
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
}
.time{
    color: #999999;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
}
.common{
    padding: 15px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ececec;
}
.common_title{
    margin-bottom: 30px;
}
</style>