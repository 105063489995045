<template>
  <div style="backgroud: #f9f9f9">
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">个人中心</h1>
    </header>
    <div class="mui-content">
      <div class="bgs">
        <div>
          账号信息 <span>{{ user.mobile }}</span>
        </div>
        <div @click="download">下载APP</div>
      </div>
      <div class="close" @click="close">退出登录</div>
      <!-- <div class="chenggong-bg">
				<div class="chenggong-box">
					<div class="">
						<img style="display: block;" src="@/assets/img/chengong-bg_01.png" >
					</div>
					<div class="chengong-m mui-text-center o_hidden color-fff">
						<h4>领取注册优惠券 开启你的上岸路</h4>
						<div class="youhuijuan clearfix">
							<div class="mui-col-xs-3 mui-pull-left color-red">
								<h4>￥<font class="" size="6" color="">9.9</font></h4>
								<p class="color-999">无门槛</p>
							</div>
							<div class="mui-col-xs-8 mui-pull-right color-red">
								<h4 class="mt1">新人专享 全站通用</h4>
								<p class="color-999">有效期：自领取日14天内</p>
							</div>
						</div>
						<div class="youhuijuan clearfix">
							<div class="mui-col-xs-3 mui-pull-left color-red">
								<h4>￥<font class="" size="6" color="">100</font></h4>
								<p class="color-999">无门槛</p>
							</div>
							<div class="mui-col-xs-8 mui-pull-right color-red">
								<h4 class="mt1">新人专享 满100使用</h4>
								<p class="color-999">有效期：自领取日14天内</p>
							</div>
						</div>
						<a class="chenggong-but mt1" @click="download">下载APP领取</a>
					</div>
					
				</div>
			</div> -->
    </div>
    <Bottom :act="4" style="padding-bottom: 50px"></Bottom>
  </div>
</template>
<script>
import { getUserInfo } from "@/api/common";
import storage from "@/utils/storage";
import Bottom from "@/components/bottom";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    download() {
      let _this = this;
      var system = _this.isIosOrAndroid();
      if (system == 1) {
        // 安卓
        var src = "https://srgongkaow.com/app/app.apk";
        var csrc = src.replace(/#/g, `&v=${new Date().getTime()}#`);
        window.location.href = csrc;
        // var iframe = document.createElement('iframe');
        // iframe.style.display = 'none';
        // iframe.src = "javascript: '<script>location.href=\"" + src + "\"<\/script>'";
        // document.getElementsByTagName('body')[0].appendChild(iframe);
      } else if (system == 2) {
        // iOS
        // let url = "https://apps.apple.com/cn/app/id1544691960";
        // let curl = url.replace(/#/g, `&v=${new Date().getTime()}#`);
        // window.location.href = curl;
		// window.open("https://apps.apple.com/cn/app/id1544691960", "_self");
		window.location="https://apps.apple.com/cn/app/id1544691960"
        // var aDiv = document.getElementById("spanId");
        // aDiv.addEventListener("click", function () {
        // });
      }
    },
    isIosOrAndroid() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      if (isAndroid) {
        return 1;
      }
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isiOS) {
        return 2;
      }
      return 0;
    },
    close() {
      storage.removeItem("ACCESS_TOKEN");
      this.$router.push("/");
    },
    loadUser() {
      let _this = this;
      getUserInfo().then((res) => {
        console.log(res);
        _this.user = res.result;
      });
    },
  },
  created() {
    this.loadUser();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f9f9f9");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style scoped>
.bgs > div {
  background: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  margin-top: 14px;
  border-bottom: 1px solid #f3f3f3;
}
.bgs > div > span {
  float: right;
}
.close {
  text-align: center;
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  background: #fff;
  height: 50px;
  line-height: 50px;
}
</style>

