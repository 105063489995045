<template>
  <div>
    <div class="mui-content">
      <div class="warp bg-fff pay mui-text-center">
        <p><img src="@/assets/img/pay-img.jpg" /></p>
        <h4 class="color-main">恭喜你付款成功</h4>
        <p class="color-999">请到APP或PC端我的订单中查看，祝您学业有成，旗开得胜~</p>
        <p class="mt1" @click="download"><a>下载APP听课</a></p>
        <p class="mt1" @click="goIndex"><a>返回首页</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import Mui from "../../assets/js/mui.min.js";
export default {
  name: "success",
  components: {},
  data() {
    return {
     
    };
  },
  methods: {
       download() {
      let _this = this
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                    var loadDateTime = new Date();
                    window.location = "shengru://";//schema链接或者universal link
                    window.setTimeout(function() { //如果没有安装app,便会执行setTimeout跳转下载页
                        var timeOutDateTime = new Date();
                        if (timeOutDateTime - loadDateTime < 5000) {
                            window.location = "https://apps.apple.com/cn/app/id1544691960"; //ios下载地址  
                        } else {
                            window.close();
                        }
                    }, 500);
                    
                } else if (navigator.userAgent.match(/android/i)) {
                    var state = null;
                    try {
                        // window.location = 'srhx://api.srgongkaow.app'; //schema链接或者universal link
                        window.setTimeout(function() {
                            window.location = "https://gongkaow.com/app/app.apk"; //android下载地址  
                        }, 500);
                    } catch (e) {}
                }
    },
    goIndex(){
       this.$router.push({path:'/'})
    }
  },
  created() {
    
  },
};
</script>