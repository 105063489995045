<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <h1 class="mui-title">模考报名</h1>
    </header>
    <div class="mock_banner">
      <div class="warp">
        <div class="banner_tab">
          <p v-for="i in list" @click="getTab(i)">{{ i.name }}</p>
        </div>
        <div class="swiper-container" id="banner">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in bannerList">
              <img :src="i.image.url" @click="openBanner(i.jump, i.jump_id)" />
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <div class="warp">
           <div class="mock_list" v-for="i in mockList">
            <p>{{i.name}}</p>
            <span>考试时间：{{ $moment(i.start_at).format("YYYY年MM月DD日")}} {{$moment(i.start_at).format("HH:mm")}}-{{$moment(i.end_at).format("HH:mm")}}</span>
            <div @click="goSelect(i)">立即报名</div>
        </div>
        <div class="mock_msg">
            <div v-html="xzDetail.text"></div>
        </div>
      </div>
  </div>
</template>
<script>
import Mui from "../../assets/js/mui.min.js";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
import { category, lists, userAbout } from "@/api/mock";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      bannerList: [],
      iconList: [],
      list: [],
      mock_category_id: null,
      mockList:[],
      xzDetail:''
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        var swiper = new Swiper("#banner", {
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: 2000,
          autoplay: {
            disableOnInteraction: false,
          },
        });
      }, 200);
    });
  },
  methods: {
    category() {
      let _this = this;
      category({}).then((res) => {
        console.log(res, "banner");
        _this.list = res.result.items;
        if (res.result.items.length > 0) {
          _this.bannerList = res.result.items[0].swiper;
        }
        _this.mock_category_id = res.result.items[0].mock_category_id;
        _this.loadList();
      });
    },
    loadList() {
      let _this = this;
      lists({
        mock_category_id: _this.mock_category_id,
      }).then((res) => {
        console.log(res, "list");
        // type 1为立即报名 2为已报名 3为开始考试 4为考试结束
        var today = new Date().getTime();
        _this.mockList = res.result.items;
        console.log(_this.list, "考试");
      });
    },
       loadAbout(){
      let _this = this;
        userAbout({
          id:31
        }).then((res) => {
          console.log(res,'大萨达大萨达是')
          _this.xzDetail = res.result
      });
    },
    goSelect(i){
        console.log(i);
        this.$router.push({path:'/mockSelect',query:{
            exam_about_category:JSON.stringify(i.exam_about_category),
            mock_id:i.mock_id
        }})
    },
    getTab(i){
        console.log(i);
        this.bannerList = i.swiper;
        this.mock_category_id = i.mock_category_id;
        this.loadList();
    }
  },
  created() {
    this.category();
    this.loadAbout();
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f9f9f9");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style scoped>
.mock_banner {
  background: url(../../assets/img/bgs.png) no-repeat 0 0;
  background-size: 100% 100%;
  margin-top: 44px;
}
.banner_tab {
  padding-top: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}
.banner_tab p {
  float: left;
  color: #fff;
  margin: 0;
  margin-right: 20px;
}
#banner img {
  border-radius: 6px;
}
.mock_list{
    background: #fff;
    border-radius: 6px;
    margin-top: 20px;
}
.mock_list>p{
    padding-top: 15px;
    padding-left:15px;
}
.mock_list>span{
     padding-left:15px;
     margin-bottom: 10px;
     display: block;
}
.mock_list div{
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #3561C7;
}
.mock_msg{
    background: #fff;
    padding: 15px;
    margin-top: 20px;
}
.mock_msg>p{
    color: #333;
}
</style>