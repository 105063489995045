<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">模考报名</h1>
    </header>
    <div class="mock">
      <div class="warp">
        <h4>报考考试类型</h4>
        <a-select
          ref="select"
          style="width: 100%"
          class="selects"
          v-model="exam_category_id"
        >
          <a-select-option
            :value="i.exam_category.exam_category_id"
            v-for="i in exam_about_category"
            >{{ i.exam_category.name }}</a-select-option
          >
        </a-select>
        <SrButton
          style="width: 60%; height: 40px; margin: 0 auto"
          @click="setUnlockExam"
          :loading="login_loading"
        >
          立即报名
        </SrButton>
      </div>
    </div>
  </div>
</template>
<script>
import SrButton from "@/components/SrButton";
import { unlockExam } from "@/api/mock";
export default {
  name: "bottom",
  components: {
    SrButton,
  },
  data() {
    return {
      exam_about_category: [],
      mock_id: null,
      exam_category_id: null,
      login_loading: false,
    };
  },
  mounted() {},
  methods: {
    setUnlockExam() {
      let _this = this;
      if (this.exam_category_id == "") {
        _this.$message.warning("请先选择分类");
        return;
      }
      unlockExam({
        exam_category_id: _this.exam_category_id,
        mock_id: _this.mock_id,
      }).then((res) => {
        if (res.status == 200) {
          _this.$message.success("操作成功");
          setTimeout(() => {
            _this.$router.push("/mock");
          }, 1500);
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
  },
  created() {
    this.exam_about_category = JSON.parse(
      this.$route.query.exam_about_category
    );
    this.mock_id = this.$route.query.mock_id;
    this.exam_category_id =
      this.exam_about_category[0].exam_category.exam_category_id;
    console.log(this.exam_about_category);
  },
};
</script>
<style scoped>
.mock {
  margin-top: 50px;
}
.selects {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>