<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">订单确认</h1>
    </header>
    <div class="mui-content">
      <div class="cart-box mui-navigate-right" v-if="goods.need_address == 1">
        <!-- 添加后 -->
        <div v-if="moren">
          <router-link :to="{ path: '/pay/addressList' }">
            <p class="color-666">
              {{ moren.province.name + moren.city.name + moren.district.name }}
            </p>
            <h4>{{ moren.address }}</h4>
            <p class="color-666">{{ moren.name }} {{ moren.mobile }}</p>
          </router-link>
        </div>
        <p v-else>
          <router-link :to="{ path: '/pay/addressList' }">
            <img class="address-tj" src="@/assets/img/cart-icon1.png" />
            <span class="color-666">选择收货地址</span>
          </router-link>
        </p>
      </div>
      <div class="cart-box" v-if="type == 1">
        <div class="clearfix">
          <div class="mui-col-xs-3 mui-pull-left">
            <img
              v-if="goods.agreement_class == 1 && goods.type == 4"
              :src="
                goods.type == 1
                  ? '/img/cart-img4.png'
                  : goods.type == 2
                  ? '/img/cart-img3.png'
                  : goods.type == 3
                  ? '/img/cart-img5.png'
                  : '/img/cart-img1.png'
              "
            />
            <img
              v-else
              :src="
                goods.type == 1  ? '/img/cart-img4.png' : goods.type == 2 ? '/img/cart-img3.png' : goods.type == 3 ? '/img/cart-img5.png' :goods.type == 8?'/img/cart-img6.png': '/img/cart-img1.png'
              "
            />
          </div>
          <div class="mui-col-xs-9 mui-pull-left cart-tex">
            <h3>{{ goods.name }}</h3>
            <p class="color-999" style="margin-left: 10px">
              X{{ goods_id[0].num }}
            </p>
          </div>
        </div>
        <div class="clearfix video-texB mt1">
          <p class="color-999 mui-pull-left">
            <!-- 限售{{ goods.sales_restriction }}人 |  -->
            已购买{{
              goods.sale_amount
            }}人
          </p>
          <p class="color-red mui-pull-right">
            ￥<font size="5" color="">{{ goods.order_price }}</font>
          </p>
        </div>
      </div>
      <!-- <div class="cart-box">
        <div class="clearfix">
          <div class="mui-col-xs-3 mui-pull-left">
            <img src="@/assets/img/cart-img2.png" />
          </div>
          <div class="mui-col-xs-9 mui-pull-left cart-tex">
            <h3>行测5天刷-判断推理...</h3>
            <p class="color-999">2020.10.20 - 2020.11.18 180课时</p>
            <p class="color-999"><span>距结束：1天15时12分08秒</span></p>
          </div>
        </div>
        <div class="clearfix video-texB mt1">
          <p class="color-999 mui-pull-left">限售1000人 | 已报名200人</p>
          <p class="color-red mui-pull-right">
            ￥<font size="5" color="">99.9</font>
          </p>
        </div>
      </div> -->
      <div class="cart-box cart-list" v-if="able.length > 0 && ableType == 1">
        <form class="mui-input-group">
          <div class="mui-input-row mui-radio" v-for="(i, index) in able">
            <label class="color-666 coupon_name">{{ i.coupon.name }}</label>
            <!-- <input
              name="radio1"
              :value="index"
              @change="couponValue"
              type="radio"
            /> -->
            <!-- <van-checkbox v-model="i.checked" @click="couponValue(index)">{{i.coupon.name}}</van-checkbox> -->
            <SrRadio
              v-model="user_coupon_id"
              :zhi="i.user_coupon_id"
              class="sr-radio coupon_radio"
              @change="couponValue"
            ></SrRadio>
          </div>
        </form>
      </div>
      <div class="cart-box cart-list" style="margin-bottom: 150px">
        <h4 class="color-666">请选择支付信息，并完成支付</h4>
        <form class="mui-input-group border-top mt05">
          <div class="mui-input-row mui-radio mt05">
            <label class="color-666"
              ><img src="@/assets/img/cart-icon2.png" /> 微信支付</label
            >
            <input
              name="radio1"
              type="radio"
              value="2"
              checked
              @change="changePay"
            />
          </div>
          <div class="mui-input-row mui-radio mt05">
            <label class="color-666"
              ><img src="@/assets/img/cart-icon3.png" />支付宝支付</label
            >
            <input name="radio1" type="radio" value="1" @change="changePay" />
          </div>
        </form>
      </div>
    </div>
    <!-- 底部 -->
    <nav class="mui-bar mui-bar-tab xq-foot">
      <div class="mui-tab-item mui-text-left" v-if="type == 1">
        <span class="color-red f-22">￥{{ price }}</span>
        <span class="color-red">已优惠￥{{ youhuiPrice }}</span>
      </div>
      <div class="mui-tab-item mui-text-right" href="">
        <a class="xq-footBut" @click="pay">立即购买</a>
      </div>
    </nav>
  </div>
</template>
<script>
import router from "@/router";
import SrRadio from "@/components/SrRadio";
import { Dialog } from "vant";
import storage from "@/utils/storage";
import {
  addressList,
  able,
  wapPay,
  ordinary,
  appPay,
  seckill,
  pintuan,
} from "@/api/common";
export default {
  name: "HelloWorld",
  components: {
    SrRadio,
  },
  props: {},
  data() {
    return {
      goods: null,
      goods_id: [],
      moren: null,
      type: null,
      able: [],
      price: 0,
      user_coupon_id: null,
      price2: 0,
      payType: 2,
      youhuiPrice: 0,
      weChatParameter: {},
      isPin: false,
      pintuan_id: null,
      pintuan_group_id: null,
      checked: false,
      ableType: 1,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
  },
  methods: {
    //   加载本人收货地址列表
    loadAddress() {
      let _this = this;
      addressList({}).then((res) => {
        var data = res.result;
        if (data.length > 0) {
          data.map(function (n) {
            if (n.is_default == 1) {
              _this.moren = n;
            }
          });
        }
        console.log(_this.moren, "默认");
      });
    },
    // 获取优惠券
    loadable() {
      let _this = this;
      able({ goods_id: _this.goods_id }).then((res) => {
        console.log(res, "优惠券");
        var data = res.result;
        data.map(function (n) {
          n.checked = false;
        });
        _this.able = data;
      });
    },
    // 计算优惠券价格
    couponValue(e) {
      console.log(e);
      // console.log(indexs)
      // console.log(this.able);
      if (e) {
        this.user_coupon_id = e;
        var indexs = null;
        this.able.map(function (n, index) {
          if (e == n.user_coupon_id) {
            console.log(index);
            indexs = index;
          }
        });
        this.price =
          parseFloat(this.goods.order_price) -
          parseFloat(this.able[indexs].coupon.value);
        this.youhuiPrice = this.able[indexs].coupon.value;
      } else {
        this.price = this.goods.order_price;
        this.youhuiPrice = 0;
      }
    },
    changePay(e) {
      this.payType = e.target.value;
    },
    pay() {
      let _this = this;
      console.log(this.goods.need_address);
      if (this.goods.need_address == 0) {
        if (this.goods.notend_seckill) {
          _this.seckill();
        } else if (this.goods.notend_pintuan && this.isPin) {
          _this.pintuan();
        } else {
          _this.ordinary();
        }
      } else {
        if (this.moren) {
          // notend_seckill有数据为秒杀课程  notend_pintuan有数据为拼团课程 如都没有则为普通课程
          if (this.goods.notend_seckill) {
            _this.seckill();
          } else if (this.goods.notend_pintuan && this.isPin) {
            _this.pintuan();
          } else {
            _this.ordinary();
          }
        } else {
          Dialog.alert({
            message: "请选择收货地址",
          }).then(() => {});
        }
      }
    },
    // 秒杀下单
    seckill() {
      let _this = this;
      var shipping_address_id = null;
      if (this.moren) {
        shipping_address_id = this.moren.shipping_address_id;
      } else {
        shipping_address_id = "";
      }
      seckill({
        seckill_id: _this.seckill_id,
        shipping_address_id: shipping_address_id,
      }).then((res) => {
        console.log(res, "-----秒杀下单");
        if (res.status == 200) {
          if (_this.is_weixn()) {
            _this.h5PayWechat(res.result);
          } else {
            _this.h5Pay(res.result);
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // 拼团下单
    pintuan() {
      let _this = this;
      var shipping_address_id = null;
      if (this.moren) {
        shipping_address_id = this.moren.shipping_address_id;
      } else {
        shipping_address_id = "";
      }
      pintuan({
        pintuan_id: _this.pintuan_id,
        shipping_address_id: shipping_address_id,
        pintuan_group_id: _this.pintuan_group_id,
      }).then((res) => {
        console.log(res, "-----拼团下单");
        if (res.status == 200) {
          if (_this.is_weixn()) {
            _this.h5PayWechat(res.result);
          } else {
            _this.h5Pay(res.result);
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // 普通下单
    ordinary() {
      let _this = this;
      var shipping_address_id = null;
      if (this.moren) {
        shipping_address_id = this.moren.shipping_address_id;
      } else {
        shipping_address_id = "";
      }
      ordinary({
        goods_id: _this.goods_id,
        shipping_address_id: shipping_address_id,
        user_coupon_id: this.user_coupon_id,
      }).then((res) => {
        console.log(res, "-----普通下单");
        if (res.status == 200) {
          _this.order_id = res.result;

          if (_this.is_weixn()) {
            _this.h5PayWechat(res.result);
          } else {
            _this.h5Pay(res.result);
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // H5支付
    h5Pay(id) {
      let _this = this;
      wapPay({
        order_id: id,
        pay_type: _this.payType,
        post_url: "http://wap.srgongkaow.com/payLoading?order_id=" + id,
      }).then((res) => {
        if (_this.goods.order_price == "0.00" || _this.goods.order_price == 0) {
          _this.$router.push({ path: "/pay/success" });
          return;
        }
        if (_this.payType == 1) {
          document.querySelector("body").innerHTML = res.result;
          document.forms[0].submit();
        } else {
          window.location.replace(res.result);
        }
      });
    },
    // 如果h5在微信浏览器中打开，调用此方法 并提示支付宝支付不可用
    h5PayWechat(id) {
      let _this = this;
      appPay({ order_id: id, pay_type: _this.payType }).then((res) => {
        console.log(res, "微信支付");
        if (_this.goods.order_price == "0.00" || _this.goods.order_price == 0) {
          _this.$router.push({ path: "/pay/success" });
          return;
        }
        if (res.msg == "请先去授权") {
          var url = _this.GetUrlRelativePath();
          storage.setItem("LOGIN_SKIP", url);
          Dialog.alert({
            message: res.msg,
          }).then(() => {
            router.push("/weixinLogin");
          });
        }
        if (_this.payType == 1) {
          Dialog.alert({
            message:
              "微信浏览器中无法进行支付宝支付，请复制链接至其他浏览器支付",
          }).then(() => {});
        } else {
          _this.wechatSdk = res.result;
          _this.weixinPay(res.result);
        }
      });
    },
    // 判断当前h5是否为在微信浏览器中打开
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    //解决微信内置对象报错
    weixinPay(data) {
      var vm = this;
      console.log(data, "-----");
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        vm.onBridgeReady(data);
      }
    },
    // 初始化微信内支付
    onBridgeReady(we) {
      console.log(we);
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: we.appId, //公众号名称，由商户传入
          timeStamp: we.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: we.nonceStr, //随机串
          package: we.packages,
          signType: "MD5", //微信签名方式：
          paySign: we.paySign, //微信签名
          jsApiList: ["chooseWXPay"],
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          vm.$router.push({
            path: "/payLoading",
            query: { order_id: vm.order_id },
          });
        }
      );
    },
    // 拦截器获取当前地址
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");

      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符

      return relUrl;
    },
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.type == 1) {
      this.goods = JSON.parse(this.$route.query.goods);
      console.log(this.goods, "goods");
      this.type = this.$route.query.type;
      var obj = {};
      obj.goods_id = this.goods.goods_id;
      obj.num = 1;
      obj.sku_id = this.$route.query.sku_id ? this.$route.query.sku_id : "";

      this.goods_id.push(obj);
      console.log(this.goods);
      this.price = this.goods.order_price;
      this.isPin = this.$route.query.isPin;
      if (this.goods.notend_seckill) {
        this.seckill_id = this.goods.notend_seckill.seckill_id;
        this.ableType = 2;
      }
      if (this.goods.notend_pintuan) {
        this.pintuan_id = this.goods.notend_pintuan.pintuan_id;
        this.ableType = 2;
        if (this.$route.query.pintuan_group_id) {
          this.pintuan_group_id = this.$route.query.pintuan_group_id;
        }
      }
    }
    this.loadAddress();
    if (localStorage.getItem("address")) {
      this.moren = JSON.parse(localStorage.getItem("address"));
      // localStorage.setItem('address', null)
    }
    this.loadable();
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
};
</script>
<style scoped>
.coupon_name {
  float: left;
  width: 70%;
}
.coupon_radio {
  float: right;
  margin-top: 12px;
}
</style>