<template>
    <div></div>
</template>
<script>
import storage from "@/utils/storage";
import SrButton from "@/components/SrButton";
import { socialiteWeiXinLogin } from "@/api/common";
export default {
  name: "bottom",
  components: {},
  components: {
    SrButton,
  },
  data() {
    return {
     
    };
  },
  methods: {
       //截取code
  getUrlCode() {
    // 截取url中的code方法
    var url = location.search;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
    }
    // console.log(theRequest)
    return theRequest;
    console.log(theRequest);
  },

  weixinLogin() {
    let _this = this
    let appid = "wx7b54bcf84dba72a6";
    let pageUrl = storage.getItem("LOGIN_SKIP");
    let gourl = 'https://wap.srgongkaow.com/call.html'
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${gourl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
    storage.setItem("weixinCode", this.getUrlCode().code);
    console.log(this.getUrlCode().code,'code')
    if (storage.getItem("weixinCode")) {
      console.log("拦截器获取的code", storage.getItem("weixinCode"));
       socialiteWeiXinLogin({ code: storage.getItem("weixinCode") }).then((res) => {
           console.log(res,'登陆后')
           if(res.result.type == 1){
               storage.setItem('ACCESS_TOKEN',res.result.token)
               console.log(pageUrl,'地址')
                _this.$router.push(pageUrl);
           }else{
               storage.setItem("head_portrait_id", res.result.data.head_portrait_id)
               storage.setItem("nicknames", res.result.data.nickname)
               storage.setItem("unionid", res.result.data.unionid)
               storage.setItem("weixin_openid", res.result.data.weixin_openid)
               _this.$router.push({path:'/login',query:{weixinType:2}})
           }
      });      
    } else {
      window.location.href = url;
    }
  },
  },
  created() {
      this.weixinLogin()
  },
};
</script>