<template>
  <div>
    <!-- 头部 -->
    <div class="radio_img">
      <SrBjAudio
        :cover="detail.image.url"
        :able="playable"
        ref="SrBjAudio"
      ></SrBjAudio>
    </div>
    <!-- 底部 -->
    <div class="mui-content">
      <div class="bg-fff">
        <div class="">
          <ul class="mui-table-view">
            <li
              class="mui-table-view-cell mui-collapse"
              v-for="i in courseList"
            >
              <a class="mui-navigate-right" href="#">{{ i.name }}</a>
              <div class="mui-collapse-content">
                <dl
                  class="clearfix dagang pos-rel"
                  v-for="item in i.radio_subchapter"
                >
                    <!-- <dt class="mui-pull-left color-999">1.1</dt> -->
                  


                  <dd class="mui-pull-right">
                    <h5>
                      {{ item.name }}
                      <span class="bg-pink" v-if="item.audition == 1"
                        >试听</span
                      >
                    </h5>
                    <!-- <h6 class="color-999">果冻 2020.10.23 09:00-20:00</h6> -->
                    <strong>
                      <a-progress
                        :percent="item.baijia_video.user_progress"
                        :show-info="false"
                      />
                    </strong>
                    <h6 class="color-yellow" style="display: inline-block">
                      已学习{{ item.baijia_video.user_progress }}%
                    </h6>
                    <router-link target="_blank"
                                     :to="{path:'/radio/detail/play?radio_id='+radio_id+'&radio_subchapter_id='+item.radio_subchapter_id}">
                             <p class="bofang" >播放</p>
                        </router-link>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { info, commentList, chapterList, video } from "@/api/radio";
import BjDianbo from "@/components/BjDianbo";
import SrBjAudio from "@/components/SrBjAudio";

export default {
  name: "bottom",
  components: {
    BjDianbo,
    SrBjAudio,
  },
  data() {
    return {
      radio_id: null,
      radio_subchapter_id: null,
      detail: {},
      courseList: [],
      playable: false,
    };
  },

  methods: {
    loadDetail() {
      let _this = this;
      info({ radio_id: _this.radio_id }).then((res) => {
        console.log(res, "详情");
        var data = res.result;
        _this.detail = data;
        _this.chapterList();
        if (_this.radio_subchapter_id) {
          _this.getVideo();
        }
      });
    },
    // 获取课程大纲
    chapterList() {
      let _this = this;
      chapterList({ radio_id: _this.radio_id }).then((res) => {
        console.log(res, "大纲");
        _this.courseList = res.result;
      });
    },
    getVideo() {
      var _self = this;
      video({ radio_subchapter_id: _self.radio_subchapter_id }).then((res) => {
        console.log(res, "12313");
        if (res.status === 200) {
          _self.video = res.result;
          _self.playable = true;
          _self.$refs.SrBjAudio.init(
            res.result.token,
            res.result.video_id,
            res.result.length
          );
        } else {
          _self.playable = false;
          _self.$srmsg.info({
            title: "提示",
            content: res.msg,
          });
        }
      });
    },
    goRadio(radio_id,radio_subchapter_id,audition){
        if(audition == 1){
           this.$router.replace({path:'/radio/detail/play',query:{radio_id:radio_id,radio_subchapter_id:radio_subchapter_id}})
        }
    }
  },
  created() {
    console.log(this.$route.query);
    this.radio_id = this.$route.query.radio_id;
    this.radio_subchapter_id = this.$route.query.radio_subchapter_id;
    this.loadDetail();
  },
};
</script>
<style scoped>
.radio_img {
  position: relative;
  margin-top: 50px;
}
.radio_fm {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
}
.radio_icon {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 40%;
  left: 45%;
}
>>> .ant-modal-content{
  background: none;
}
</style>