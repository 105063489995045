<template>
  <div>
	  	<header class="mui-bar mui-bar-nav wx-top">
			<a class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"></a>
			<h1 class="mui-title">拼团</h1>
		</header>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <div class="video-tex">
          <h4>{{ goods.name }}</h4>
          <h5 class="color-999">{{ goods.start }} - {{ goods.end }} 180课时</h5>
          <h4 class="color-red">
            拼团价：{{ goods.order_price }}
            <small class="color-999"
              ><s>￥{{ goods.real_price }}</s></small
            >
          </h4>
        </div>
        <div class="pintuan border-top">
          <h4 class="mui-text-center mt1">参与【{{pintuan.chief.nickname}}】的拼单</h4>
          <p class="mui-text-center color-999">
            仅剩{{pintuan.pintuan.number - pintuan.pintuan_user_count}}个名额，
            <a-statistic-countdown
              :value="goods.notend_pintuan.end_at"
              format="D 天 H 时 m 分 s 秒"
            />
            后结束
          </p>
          <ul class="mui-text-center">
            <li v-for="item in list">
              <img v-if="item.user" :src="item.user.head_portrait.url" class="tx" />
              <img v-else src="@/assets/img/pintuan-mrtx.png" />
              <span v-if="item.is_chief == 1">拼主</span>
            </li>
          </ul>
          <p class="mui-text-center mt1">
            <button
              id="alertBtn"
              class="bg-blue color-fff pintuan-but2"
              type="button" @click="goPay"
            >
              参与拼单
            </button>
          </p>
        </div>
      </div>

      <div class="pintuan-tex bg-fff mt1">
        <h3>拼团须知</h3>
        <p>
          1.拼团活动，开团成为团长，并邀请好友参团，在拼团有效时间内凑齐成团人数，即可拼团成功，商城将会安排发货；
        </p>
        <p>
          2.
          拼团有效时间内未凑齐成团人数，即拼团失败。系统自动取消订单并全额退款，支付金额将会原路退回付款账户；
        </p>
        <p>
          3.
          拼团有效时间为24小时，即拼团允许邀请好友参团的时间，可在拼团详情页查看倒计时；
        </p>
        <p>4. 拼团商品可以使用积分，但需要积分数量≥100时才可进行抵扣；</p>
        <p>5. 拼团商品不可以使用优惠券；</p>
      </div>
    </div>
  </div>
</template>
<script>
import { pintuanUser } from "@/api/common";
export default {
  name: "bottom",
  data() {
    return {
      pintuan: null,
      goods: null,
	    pinUser:[],
      list:[]
    };
  },

  methods: {
	//   获取团员列表
	loadpintuanUser(){
		let _this = this
		pintuanUser({ pintuan_group_id: _this.pintuan.pintuan_group_id }).then((res) => {
        console.log(res, "团员");
           _this.list = res.result
                    //不足补齐
          if (_this.list.length < _this.pintuan.pintuan.number) {
            for (let i = 0; i <= (_this.pintuan.pintuan.number - _this.list.length); i++) {
              _this.list.push({})
            }
          }
          console.log(_this.list)
      });
	},
  goPay(){
      this.$router.push({path:'/pay',query:{goods:JSON.stringify(this.goods),type:1,isPin:true,pintuan_group_id:this.pintuan.pintuan_group_id}})
    },
  },
  created() {
    this.pintuan = JSON.parse(this.$route.query.pintuan);
    this.goods = JSON.parse(this.$route.query.goods);
    var data = JSON.parse(this.$route.query.goods);
    data.start = data.notend_pintuan.start_at.substring(0, 10);
    data.end = data.notend_pintuan.end_at.substring(0, 10);
    this.goods = data;
  	this.loadpintuanUser();
    console.log(this.pintuan,'pintuan');
    console.log(this.goods,'goods');
  },
};
</script>
<style scoped>
>>> .ant-statistic {
  display: inline-block;
  font-size: 14px;
  line-height: 0;
}

>>> .ant-statistic-content {
  display: inline-block;
}

>>> .ant-statistic-content-value {
  font-size: 14px;
}
.tx{
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
</style>
