import request from '@/utils/request'
export function category(parameter) {
    return request({
        url: '/api/mock/category',
        method: 'post',
        data: parameter
    })
}
export function lists(parameter) {
    return request({
        url: '/api/mock/lists',
        method: 'post',
        data: parameter
    })
}
// 协议通用
export function userAbout(parameter) {
    return request({
        url: '/api/user/about',
        method: 'post',
        data: parameter
    })
}

export function unlockExam(parameter) {
    return request({
        url: '/api/mock/unlockExam',
        method: 'post',
        data: parameter
    })
}