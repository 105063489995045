<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="
          mui-action-back mui-icon mui-icon-left-nav mui-pull-left
          color-666
        "
      ></a>
      <h1 class="mui-title">{{ name }}</h1>
    </header>
    <div class="mui-content">
      <div class="wx-list" v-for="i in list" v-if="i.type == 1" @click="goCourseDetail( i.course_id)">
      
          <p class="color-red" :style="'color:' + i.course.subtitle_color">
            {{ i.course.subtitle }}
          </p>
          <h4>{{ i.course.name }}</h4>
          <!-- <p class="color-999">{{ i.start }}-{{ i.end }}</p> -->
          <div class="wx-listImg mt05 clearfix">
            <ul>
              <li class="mui-text-center" v-for="item in i.course.teacher">
                <img style="border-radius: 50%" :src="item.avatar.url" />
                <p class="color-999">{{ item.name }}</p>
              </li>
            </ul>
          </div>
          <div class="wx-listPrice clearfix">
            <p class="mui-pull-left color-999">
              限售{{ i.course.goods.sales_restriction }}人 | 已报名{{
                i.course.goods.sale_amount
              }}人
            </p>
            <h3 class="miaosha">{{ i.real_price }}</h3>
            <p class="mui-pull-right color-red f-18">￥{{ i.order_price }}</p>
          </div>

          <span class="wx-label" v-if="i.course.goods.notend_seckill"
            ><img src="@/assets/img/wx-miaosha.png"
          /></span>
          <span class="wx-label" v-if="i.course.goods.agreement_class == 1"
            ><img src="@/assets/img/wx-agreement.png"
          /></span>
          <span class="wx-label" v-if="i.course.vip_free == 1"
            ><img src="@/assets/img/wx-free.png"
          /></span>
      </div>
      <div class="wx-list" v-else @click="goCourseGroupDetail(i.course_package_id)">
          <p
            class="color-red"
            :style="'color:' + i.course_package.subtitle_color"
          >
            {{ i.course_package.subtitle }}
          </p>
          <h4>{{ i.course_package.name }}</h4>
          <p class="mt05"><img :src="i.course_package.first_cover.url" /></p>
          <div class="wx-listPrice clearfix">
            <!-- <p class="mui-pull-left color-999">
              限售{{ i.course_package.goods.sales_restriction }}人 | 已报名{{
                i.course_package.goods.sale_amount
              }}人
            </p> -->
            <!-- <h3 class="miaosha">{{ i.real_price }}</h3> -->
            <p class="mui-pull-right color-red f-18">￥{{ i.order_price }}</p>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { list } from "@/api/course";
export default {
  name: "bottom",
  data() {
    return {
      course_category_id: null,
      course_type_id: null,
      list: [],
      name: null,
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
  },
  methods: {
    // 加载列表
    loadList() {
      let _this = this;
      list({
        course_type_id: this.course_type_id,
        page_size: 20,
        page: 1,
      }).then((res) => {
        var data = res.result.items;
        console.log(data, "ddddd");
        // if (storage.getItem("userInfo") == null) {
        //   var url = _this.GetUrlRelativePath();

        //   storage.setItem("LOGIN_SKIP", url);
        //   _this.$router.push({ path: "/login?action=login" });
        // }
        var userInfo = storage.getItem("userInfo");
        //   课程 1.划线价格 没活动的时候 展示原价   有活动的时候展示会员VIP6的价格  2.展示价格有活动的时候展示活动价  没活动的时候展示 自己用户当前等级的价格
        data.map(function (n) {
          if (n.course_package) {
            //   课程包课程-------------------------------
            if (
              n.course_package.goods.notend_pintuan ||
              n.course_package.goods.notend_seckill
            ) {
              if (n.course_package.goods.notend_seckill) {
                n.order_price =
                  n.course_package.goods.notend_seckill.goods.order_price;
                n.real_price = n.course_package.goods.real_price;
              }
              if (n.course_package.goods.notend_pintuan) {
                n.order_price =
                  n.course_package.goods.notend_pintuan.goods.order_price;
                n.real_price = n.course_package.goods.real_price;
              }
            } else {
              n.real_price = n.course_package.goods.real_price;
              n.order_price = n.course_package.goods.order_price;
            }
          } else {
            // 普通课程------------------------------------------------------
            if (
              n.course.goods.notend_pintuan ||
              n.course.goods.notend_seckill
            ) {
              if (n.course.goods.notend_seckill) {
                n.order_price = n.course.goods.notend_seckill.goods.order_price;
                n.real_price = n.course.goods.real_price;
              }
              if (n.course.goods.notend_pintuan) {
                n.order_price = n.course.goods.notend_pintuan.goods.order_price;
                n.real_price = n.course.goods.real_price;
              }
            } else {
              if (n.course.goods.vip_price.length > 0) {
                // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
                if (userInfo) {
                  if (userInfo.now_user_member) {
                    n.course.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        userInfo.now_user_member.user_member_config_id
                      ) {
                        n.order_price = s.price;
                      }
                    });
                  }
                  n.real_price = n.course.goods.real_price;
                } else {
                  n.order_price = n.course.goods.order_price;
                }
              } else {
                n.order_price = n.course.goods.order_price;
              }
            }
          }
        });
        _this.list = data;
      });
    },
    goCourseDetail(id) {
      window.location.href =
        "https://wap.srgongkaow.com/school/courseDetail?course_id=" + id;
    },
    goCourseGroupDetail(id) {
      window.location.href =
        "https://wap.srgongkaow.com/school/courseGroupDetail?course_package_id=" +
        id;
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
  },
  created() {
    this.course_category_id = this.$route.query.course_category_id;
    this.course_type_id = this.$route.query.course_type_id;
    this.name = this.$route.query.name;
    this.loadList();
  },
};
</script>
<style scoped>
.miaosha {
  float: right;
  font-size: 14px;
  padding-top: 6px;
  padding-left: 10px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
}
</style>