<template>
  <div>
    <iframe id="iframe" class="iframe" :src="url"></iframe>
    <view class="downloadBtn">
      <view>文件下载</view>
    </view>
  </div>
</template>
  <script>
import storage from "@/utils/storage";
export default {
  name: "bottom",
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    // const iframe = document.querySelector("#iframe");
    // // 处理兼容问题
    // if (iframe.attachEvent) {
    //   iframe.attachEvent("onload", function () {
    //     // iframe加载完毕以后执行操作
    //     console.log("iframe已加载完毕");
    //     // iframe加载完毕之后 获取iframe中的元素
    //     let iframeId = document
    //       .getElementById("iframe")
    //       .contentWindow.document.getElementById("app");
    //     if (iframeId != null) {
    //       // setAttribute 给元素添加属性
    //       iframeId.setAttribute("style", "background: pink !importent");
    //     }
    //   });
    // } else {
    //   iframe.onload = function () {
    //     // iframe加载完毕以后执行操作
    //     console.log("iframe已加载完毕");
    //     let iframeId = document
    //       .getElementById("iframe")
    //       .contentWindow.document.getElementById("app");
    //     if (iframeId != null) {
    //       iframeId.setAttribute("style", "background: pink !importent");
    //     }
    //   };
    // }
  },
  methods: {},
  created() {
    var url = storage.getItem('courseDownloadFile');
    this.url = url.replace('http','https');
  },
};
</script>
  <style scoped>
.iframe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow-y: hidden;
}
.downloadBtn {
  position: fixed;
  width: 100%;
  bottom: 30px;
  left: 0;
}
.downloadBtn view {
  width: 90%;
  background: #3561c7;
  color: #fff;
  line-height: 42px;
  text-align: center;
  border-radius: 4rem;
}
</style>
