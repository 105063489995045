import storage from '@/utils/storage'
import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import router from '@/router'
import {
    Modal
} from "ant-design-vue";

let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let Cancel = axios.Cancel

let removePending = (ever) => {
    // 忽略首页文章列表接口重复调用
    if (ever.url != '/api/article') {
        for (let p in pending) {
            if (pending[p].u === ever.url + '&' + ever.method) { //当当前请求在数组中存在时执行函数体
                pending[p].f(); //执行取消操作
                pending.splice(p, 1); //把这条记录从数组中移除
            }
        }
    }
}

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: 'https://api.srgongkaow.com/',
    // baseURL: 'http://erqiapi.srgongkaow.com/',
    timeout: 600000 // 请求超时时间
})


// 异常拦截处理器
const errorHandler = (error) => {
    if (error instanceof Cancel) {
        return Promise.reject({
            message: 'request cancellation'
        })
    } else {
        return Promise.reject(error)
    }
}

/**
 * 请求拦截
 */
request.interceptors.request.use(config => {
    //中断重复请求
    removePending(config); //在一个ajax发送前执行一下取消操作
    config.cancelToken = new cancelToken((c) => {
        pending.push({
            u: config.url + '&' + config.method,
            f: c
        });
    });

    const token = storage.getItem('ACCESS_TOKEN')
    if (token) {
        config.headers['Access-Token'] = token
    }
    // type传入Localhost时 请求本地JSON文件
    if (config.type == "Localhost") {
        config.baseURL = ''
    }
    config.headers['App-Type'] = 'PC'
    return config
}, errorHandler)

// 响应拦截
request.interceptors.response.use((response) => {
    if (response.data.status === 40101) { //需要登录
        Modal.destroyAll();
        //获取当前页地址
        var url = GetUrlRelativePath()
        storage.setItem('LOGIN_SKIP', url)
        console.log(storage.getItem('LOGIN_SKIP'), '拦截器缓存地址')
        storage.removeItem('ACCESS_TOKEN')
        notification.error({
            key: 40101,
            message: '提示',
            description: '请先登录'
        })
        setTimeout(function () {
            if (is_weixn()) {
                router.push('/weixinLogin')
            } else {
                router.push('/login')
            }
            // router.push('/login')
        }, 700)

    }
    if (response.data.status === 40102) { //Token过期
        Modal.destroyAll();
        var url = GetUrlRelativePath()
        storage.removeItem('ACCESS_TOKEN')
        storage.setItem('LOGIN_SKIP', url)
        notification.error({
            key: 40102,
            message: '提示',
            description: '登录已过期，请重新登录。'
        })
        setTimeout(function () {
            if (is_weixn()) {
                router.push('/weixinLogin')
            } else {
                router.push('/login')
            }
        }, 700)
    }
    if (response.data.status === 40103) { //Token过期
        Modal.destroyAll();
        var url = GetUrlRelativePath();
        storage.removeItem('ACCESS_TOKEN')
        storage.setItem('LOGIN_SKIP', url)
        notification.error({
            key: 40103,
            message: '提示',
            description: '您的账号在别处登录'
        })
        setTimeout(function () {
            if (is_weixn()) {
                router.push('/weixinLogin')
            } else {
                router.push('/login')
            }
        }, 700)
    }
    return response.data
}, errorHandler)

function GetUrlRelativePath() {
    var url = document.location.toString();
    var arrUrl = url.split("//");
    var start = arrUrl[1].indexOf("/");
    var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
    return relUrl;
}

// 获取是否为微信打开
function is_weixn() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}


export default request