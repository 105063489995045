var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"new_box"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.detail.title))]),_c('div',{staticClass:"time"},[_vm._v("发布时间"+_vm._s(_vm.detail.created_at))]),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.detail.content)}})]),_c('div',{staticClass:"common"},[_c('div',{staticClass:"common_title"},[_vm._v("已有"+_vm._s(_vm.detail.comment.length)+"次留言")]),_vm._l((_vm.detail.comment),function(i){return _c('dl',{staticClass:"pinglun clearfix"},[_c('dt',{staticClass:"mui-pull-left"},[_c('img',{staticClass:"dis-inb tx",attrs:{"src":i.user.head_portrait.url}})]),_c('dd',{staticClass:"mui-pull-right pos-rel"},[_c('p',[_vm._v(" "+_vm._s(i.user.nickname)+" "),(i.user.now_user_member)?_c('img',{staticClass:"dis-inb jibie",attrs:{"src":i.user.now_user_member.user_member_config_id == 1
                        ? '/img/jibie.png'
                        : i.user.now_user_member.user_member_config_id == 2
                        ? '/img/jibie2.png'
                        : i.user.now_user_member.user_member_config_id == 3
                        ? '/img/jibie3.png'
                        : i.user.now_user_member.user_member_config_id == 4
                        ? '/img/jibie4.png'
                        : i.user.now_user_member.user_member_config_id == 5
                        ? '/img/jibie5.png'
                        : '/img/jibie6.png'}}):_vm._e()]),_c('p',{staticClass:"color-666"},[_vm._v(" "+_vm._s(i.content)+" ")]),_c('p',{staticClass:"clearfix"},_vm._l((i.image),function(item){return _c('span',[_c('van-cell',{attrs:{"is-link":""},on:{"click":function($event){return _vm.showPopup(item.url)}}},[_c('img',{attrs:{"src":item.url}})])],1)}),0),_c('p',{staticClass:"pinglun-zan color-999",on:{"click":function($event){return _vm.clickLike(i.camp_diary_id, i.is_like)}}},[(i.is_like == 0)?_c('img',{attrs:{"src":require("@/assets/img/praise.png")}}):_c('img',{attrs:{"src":require("@/assets/img/praise-hover.png")}}),_vm._v(_vm._s(i.likes)+" ")])])])})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"mui-bar mui-bar-nav wx-top"},[_c('h1',{staticClass:"mui-title"},[_vm._v("资讯详情")])])}]

export { render, staticRenderFns }