<template>
  <div>
    <div class="kefu" @click="openKefu">
      <img src="@/assets/img/kefu.png" />
    </div>
    <div class="motal" v-show="motalShow">
      <div class="motal_bg">
        <div class="motal_title">客服二维码</div>
        <img :src="imgUrl" alt="" />
      </div>
      <p
        class="tanchuang-close mui-text-center"
        style="bottom: 28%"
        @click="close"
      >
        <img src="@/assets/img/tc-close.png" />
      </p>
    </div>
  </div>
</template>
<script>
import { userAbout } from "@/api/mock";
export default {
  name: "kefu",
  props: {},
  data() {
    return {
      motalShow: false,
      imgUrl:null
    };
  },
  methods: {
    openKefu() {
      this.motalShow = true;
    },
    close() {
      this.motalShow = false;
    },
    userAbout() {
      let _this = this;
      userAbout({id:13}).then((res) => {
        console.log(res,'客服链接')
        _this.imgUrl = res.result.image.url
      });
    },
  },
  created() {
    this.userAbout();
  },
};
</script>
<style scoped>
.motal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
}
.motal_bg {
  background: url(../../assets/img/pop-bg.png);
  width: 60%;
  margin: 0 auto;
  margin-top: 200px;
  background-size: 100% 100%;
}
.motal_bg img {
  margin: 0 auto;
  padding-bottom: 30px;
}
.motal_title {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}
</style>