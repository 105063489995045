<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">收货地址</h1>
    </header>
    <div class="contanier">
      <div class="list" v-for="i in list">
        <div class="list_left">
          <div class="list_name">
            <div>{{i.name}}</div>
            <span>{{i.mobile}}</span>
            <p v-if="i.is_default == 1">默认</p>
          </div>
          <div class="list_d">
              {{i.province.name + i.city.name + i.district.name + i.address}}
          </div>
        </div>
        <div class="list_right" @click="go(i)">
            选择
        </div>
      </div>
      <div class="btns" @click="goAdd">
          添加收货地址
      </div>
    </div>
  </div>
</template>
<script>
import { addressList } from "@/api/common";
export default {
  name: "address",
  data() {
    return {
      list:[]
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
  },
  methods: {
     //   加载本人收货地址列表
    loadAddress() {
      let _this = this;
      addressList({}).then((res) => {
        console.log(res, "收货地址");
        _this.list = res.result
      });
    },
    go(e){
      console.log(e)
      localStorage.setItem('address', JSON.stringify(e))
      this.$router.go(-1)
    },
    goAdd(){
      this.$router.push('/pay/addAddress')
    }
  },
  created() {
    this.loadAddress()
  },
};
</script>
<style scoped>
.contanier {
  padding: 15px;
}
.list {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
  overflow: hidden;
  position: relative;
}
.list:first-child {
  margin-top: 40px;
}
.list_name div {
  display: inline-block;
  margin-right: 15px;
  font-size: 16px;
}
.list_name span {
  color: #777777;
  margin-right: 10px;
}
.list_name p {
  display: inline-block;
  background-color: #3561c7;
  color: #fff;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 4px;
}
.list_left{
    width: 80%;
    float: left;
}
.list_right{
    text-align: center;
    width: 20%;
    position:absolute;
    top: 40%;
    right: 0%;
    border-left: 1px solid #f0f0f0;
}
.btns{
    width: 93%;
    background-color: #3561c7;
    color: #fff;
    position: fixed;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    bottom: 4%;
}
</style>