<template>
  <div></div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "jump",
  data() {
    return {
      id: null,
      share_user_id: null,
      type: null,
    };
  },
  methods: {},
  created() {
    // 跳转类型 1课程 2课程包 3电台 4训练营
    Toast.loading({
      message: "跳转中...",
      forbidClick: true,
      loadingType: "spinner",
    });

    if (this.$route.query.type == 1) {
      this.$router.push({
        path: "/school/courseDetail",
        query: { course_id: this.$route.query.goods_id,share_user_id:this.$route.query.share_user_id },
      });
    } else if (this.$route.query.type == 2) {
      this.$router.push({
        path: "/school/courseGroupDetail",
        query: { course_package_id:this.$route.query.goods_id,share_user_id:this.$route.query.share_user_id },
      });
    } else if (this.$route.query.type == 3) {
      this.$router.push({ path: "/radio/detail", query: { id: this.$route.query.goods_id,share_user_id:this.$route.query.share_user_id } });
    } else if(this.$route.query.type == 4){
      this.$router.push({ path: "/school/detail", query: { id:this.$route.query.goods_id,share_user_id:this.$route.query.share_user_id} });
    }else if(this.$route.query.type == 66){
        // type为66的时候 跳转邀请注册
        this.$router.push({ path: "/register", query: {share_user_id:this.$route.query.share_user_id} });
    }
  },
};
</script>