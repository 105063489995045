<template>
  <div>
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">电台详情</h1>
    </header>
    <div class="mui-content">
      <div class="bg-fff">
        <div class="warp clearfix">
          <div class="radio-img mui-col-xs-4 mui-pull-left pos-rel">
            <img :src="detail.image.url" />
            <span
              ><img src="@/assets/img/radio-icon2.png" />播放{{
                detail.playback_volume
              }}次</span
            >
          </div>
          <div class="radio-tex mui-col-xs-8 mui-pull-left">
            <h4>{{ detail.name }}</h4>
            <h5 class="color-999 mt05">
              {{ detail.period }}课时
            </h5>
            <h5
              class="color-main mt05"
              :style="'color:' + detail.subtitle_color"
            >
              {{ detail.subtitle }}
            </h5>
            <h5 class="color-999 mt05">
              限售{{ detail.goods.sales_restriction }}人/已报名：{{
                detail.goods.sale_amount
              }}人
            </h5>
            <h5 class="color-pink mt05"  v-if="detail.goods.notend_seckill">
              距秒杀结束：
              <a-statistic-countdown
                :value="detail.end_at" 
                format="D 天 H 时 m 分 s 秒"
              />
            </h5>
            <h5 class="color-pink mt05"  v-if="detail.goods.notend_pintuan">
              距拼团结束：
              <a-statistic-countdown
                :value="detail.end_at" 
                format="D 天 H 时 m 分 s 秒"
              />
            </h5>
          </div>
        </div>
        <div class="radio-teacher warp border-top mt1">
          <ul>
            <li class="mui-text-center" v-for="i in detail.teacher">
              <img class="tx" style="width:40px;height:40px" :src="i.avatar.url" />
              <p class="color-999">{{ i.name }}</p>
            </li>
          </ul>
        </div>
      </div>

      <!-- 拼团 -->
      <div class="bg-fff mt1" v-if="detail.goods.notend_pintuan">
        <PinTuan
          :pintuan_id="detail.goods.notend_pintuan.pintuan_id"
          :goods="detail.goods"
        ></PinTuan>
      </div>

      <div id="slider" class="mui-slider kecheng-tab">
        <div
          id="sliderSegmentedControl"
          class="mui-slider-indicator mui-segmented-control mui-segmented-control-inverted"
        >
          <a class="mui-control-item mui-active" @click="getTab(0)">
            课程介绍
          </a>
          <a class="mui-control-item" @click="getTab(1)"> 课题大纲 </a>
          <a class="mui-control-item" @click="getTab(2)"> 学员评价 </a>
        </div>
        <div class="mui-slider-group">
          <div
            v-if="tabIndex == 0"
            class="mui-slider-item mui-control-content mui-active"
          >
            <div v-html="imgWH(detail.introduce)"></div>
          </div>
          <div v-if="tabIndex == 1" class="mui-slider-item mui-control-content">
            <div class="mui-card">
              <ul class="mui-table-view">
                <li
                  class="mui-table-view-cell mui-collapse"
                  v-for="i in courseList"
                >
                  <a class="mui-navigate-right" href="#">{{ i.name }}</a>
                  <div class="mui-collapse-content">
                    <dl
                      class="clearfix dagang pos-rel"
                      v-for="item in i.radio_subchapter" @click="download"
                    >
                      <!-- <dt class="mui-pull-left color-999">1.1</dt> -->
                      <dd class="mui-pull-right">
                        <h5>
                          {{ item.name }}
                          <span class="bg-pink" v-if="item.audition == 1"
                            >试听</span
                          >
                        </h5>
                        <!-- <h6 class="color-999">果冻 2020.10.23 09:00-20:00</h6> -->
                        <strong>
                          <a-progress
                            :percent="item.baijia_video.user_progress"
                            :show-info="false"
                          />
                        </strong>
                        <h6 class="color-yellow" style="display: inline-block">
                          已学习{{ item.baijia_video.user_progress }}%
                        </h6>
                        <p
                          class="bofang"
                        >
                          播放
                        </p>
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="tabIndex == 2" class="mui-slider-item mui-control-content">
            <dl class="pinglun clearfix" v-for="i in commentLists">
              <dt class="mui-pull-left">
                <img class="dis-inb tx" :src="i.user.head_portrait.url" />
              </dt>
              <dd class="mui-pull-right pos-rel">
                <p>
                  {{ i.user.nickname }}
                  <img
                    class="dis-inb jibie"
                    v-if="i.user.now_user_member"
                    :src="
                      i.user.now_user_member.user_member_config_id == 1
                        ? '/img/jibie.png'
                        : i.user.now_user_member.user_member_config_id == 2
                        ? '/img/jibie2.png'
                        : i.user.now_user_member.user_member_config_id == 3
                        ? '/img/jibie3.png'
                        : i.user.now_user_member.user_member_config_id == 4
                        ? '/img/jibie4.png'
                        : i.user.now_user_member.user_member_config_id == 5
                        ? '/img/jibie5.png'
                        : '/img/jibie6.png'
                    "
                  />
                  <!-- <em class="bg-blue zhiding">置顶</em> -->
                </p>
                <p class="color-666">
                  {{ i.content }}
                </p>
                <p class="clearfix">
                  <span v-for="item in i.image">
                    <van-cell is-link @click="showPopup(item.url)"
                      ><img :src="item.url"
                    /></van-cell>
                  </span>
                </p>
                <p
                  class="pinglun-zan color-999"
                  @click="clickLike(i.comment_id, i.is_like)"
                >
                  <img
                    src="@/assets/img/praise.png"
                    v-if="i.is_like == 0"
                  /><img src="@/assets/img/praise-hover.png" v-else />{{
                    i.likes
                  }}
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <nav class="mui-bar mui-bar-tab xq-foot" v-if="detail.has_unlock == 0">
      <div class="mui-tab-item mui-text-left" href="">
        <span class="color-red f-22">￥{{ detail.goods.order_price }}</span>
      </div>
      <div
        class="mui-tab-item mui-text-right"
        style="width: 30%"
        v-if="detail.goods.notend_pintuan"
      >
        <a class="pintuan-but" style="float: right" @click="payPinTuan"
          >发起拼团<small>({{ detail.goods.notend_pintuan.number }}人成团)</small>
        </a>
        <a
          class="pintuan-but"
          style="
            display: inline-block;
            height: 40px;
            float: right;
            line-height: 40px;
            background: #eebfd7;
          "
          @click="goPay"
          >单独购买</a
        >
      </div>
      <div class="mui-tab-item mui-text-right" v-else>
        <a class="xq-footBut" @click="goPay">立即购买</a>
      </div>
    </nav>
    <KeFu></KeFu>
    <InviteBuy :share_user_id="share_user_id" :share_id="share_id" @motalShow="getShow" v-show="InviteShow"></InviteBuy>
    <van-popup v-model="imgShow" style="width: 70%">
      <img :src="imgUrl" alt="" />
    </van-popup>
  </div>
</template>
<script>
import Mui from "../../assets/js/mui.min.js";
import { info, commentList, chapterList } from "@/api/radio";
import { commentLike, getShare } from "@/api/common";
import InviteBuy from "@/components/InviteBuy";
import PinTuan from "@/components/PinTuan";
import KeFu from "@/components/kefu";
export default {
  name: "bottom",
  components: {
    PinTuan,
    KeFu,
    InviteBuy
  },
  data() {
    return {
      id: null,
      detail: {},
      tabIndex: 0,
      commentLists: [],
      courseList: [],
      imgShow: false,
      imgUrl: null,
       InviteShow: false,
      share_user_id:null,
      share_id:null
    };
  },

  methods: {
    loadDetail() {
      let _this = this;
      info({ radio_id: _this.id }).then((res) => {
        console.log(res, "详情");
        var data = res.result;
        data.start = data.start_at.substring(0, 10);
        data.end = data.end_at.substring(0, 10);
        _this.detail = data;
         if (_this.$route.query.share_user_id) {
          _this.loadShare();
        }
        _this.loadCommentList();
        _this.chapterList();
      });
    },
    getTab(index) {
      this.tabIndex = index;
    },
    loadCommentList() {
      let _this = this;
      commentList({ radio_id: _this.id }).then((res) => {
        console.log(res, "评论列表");
        _this.commentLists = res.result.items;
      });
    },
    imgWH(string) {
      //正则替换图片宽高
      if (string) {
        string = string.replace(/ height="\d+"/g, ' height="auto"');
        string = string.replace(/ width="\d+"/g, ' width="100%"');
        return string;
      } else {
        return "";
      }
    },
    // 获取课程大纲
    chapterList() {
      let _this = this;
      chapterList({ radio_id: _this.id }).then((res) => {
        console.log(res, "大纲");
        _this.courseList = res.result;
      });
    },
    goRadio(radio_id, radio_subchapter_id, audition) {
      if (audition == 1) {
        this.$router.push({
          path: "/radio/detail/play",
          query: {
            radio_id: radio_id,
            radio_subchapter_id: radio_subchapter_id,
          },
        });
      }
    },
    goPay() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: false,
        },
      });
    },
      payPinTuan() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: true,
        },
      });
    },
    // 点赞笔记
    clickLike(comment_id, is_like) {
      let _this = this;
      if (is_like == 0) {
        _this.commentLike(comment_id, 1);
      } else {
        _this.commentLike(comment_id, 0);
      }
    },
    commentLike(id, status) {
      let _this = this;
      commentLike({ comment_id: id, status: status }).then((res) => {
        console.log(res, "点赞");
        if (res.status == 200) {
          _this.loadCommentList();
        }
      });
    },
    showPopup(url) {
      this.imgShow = true;
      this.imgUrl = url;
    },
    // 获取分享产品id
    loadShare() {
      let _this = this;
      getShare({ goods_id: _this.detail.goods.goods_id }).then((res) => {
        console.log(res, "分享产品id");
        if(res.result){
          _this.InviteShow = true
          _this.share_id = res.result.share_id
        }
      });
    },
    getShow(e){
      this.InviteShow = e
    },
     download() {
      let _this = this
      Mui.alert("请到APP端下载课程资料", "温馨提示", function () {
          if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
                    var loadDateTime = new Date();
                    window.location = "shengru://goodId=" + _this.detail.goods.relation_id + '&type=' + _this.detail.goods.type;//schema链接或者universal link
                    window.setTimeout(function() { //如果没有安装app,便会执行setTimeout跳转下载页
                        var timeOutDateTime = new Date();
                        if (timeOutDateTime - loadDateTime < 5000) {
                            window.location = "https://apps.apple.com/cn/app/id1544691960"; //ios下载地址  
                        } else {
                            window.close();
                        }
                    }, 500);
                    
                } else if (navigator.userAgent.match(/android/i)) {
                    var state = null;
                    try {
                        window.location = 'srhx://api.srgongkaow.app/openwith?goodId='+ _this.detail.goods.relation_id + '&type=' + _this.detail.goods.type ; //schema链接或者universal link
                        window.setTimeout(function() {
                            window.location = "https://gongkaow.com/app/app.apk"; //android下载地址  
                        }, 500);
                    } catch (e) {}
                }
      });
    },
  },
  created() {
     if (document.body.clientWidth >= 1200) {
      window.location.href =
        "http://srgongkaow.com/radio/info/" +
        this.$route.query.id;
    }
    this.id = this.$route.query.id;
    this.share_user_id = this.$route.query.share_user_id
    this.loadDetail();
  },
};
</script>
<style scoped>
>>> .ant-statistic {
  display: inline-block;
  font-size: 14px;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}
.tx {
  border-radius: 50%;
  width: 50px !important;
  height: 50px!important;
}
.mui-collapse:last-child {
  margin-bottom: 40px;
}
p {
  margin-bottom: 0;
}
ul li {
  margin-bottom: 0;
}
.xq-foot{
  padding-bottom: 20px;
}
</style>