import request from '@/utils/request'
export function type(parameter) {
    return request({
        url: '/api/camp/type',
        method: 'post',
        data: parameter
    })
}
// 获取课程列表
export function campList(parameter) {
    return request({
        url: '/api/camp',
        method: 'post',
        data: parameter
    })
}
// 获取课程详情
export function campDetail(parameter) {
    return request({
        url: '/api/camp/detail',
        method: 'post',
        data: parameter
    })
}
// 获取学员笔记列表
export function campstuList(parameter) {
    return request({
        url: '/api/camp/campList',
        method: 'post',
        data: parameter
    })
}
// 课程主题列表
export function theme(parameter) {
    return request({
        url: '/api/camp/theme',
        method: 'post',
        data: parameter
    })
}
// 笔记点赞
export function campLike(parameter) {
    return request({
        url: '/api/camp/like',
        method: 'post',
        data: parameter
    })
}