<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top" style="height:auto">
      <!-- 顶部选项卡滑动 -->
      <div
        :class="tabIndex == index ? 'tabs active' : 'tabs'"
        v-for="(i, index) in typeList"
        @click="getTab(index)"
      >
        <span>{{ i.name }}</span>
      </div>
    </header>
    <div class="mui-content" style="margin-top:40px">
      <!-- 选项卡内容 -->
      <div class="bg-fff o_hidden">
        <!-- 网校banner -->
        <div class="swiper-container banner inside-ban">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="item in typeIndex.banner"
              @click="goBanner(item.jump_id, item.jump)"
            >
              <img :src="item.image.url" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <!-- 网校导航 -->
        <van-tabs type="card" swipeable swipe-threshold="0">
          <van-tab
            v-for="i in typeIndex.course_type"
          >
            <template #title >
              <img class="card_icon" :src="i.icon.url"  @click="goLists(i.course_category_id, i.course_type_id, i.name)"/>
              <p style="color: #3561c7; margin-top: 10px" @click="goLists(i.course_category_id, i.course_type_id, i.name)">{{ i.name }}</p>
            </template>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 网校列表 -->
      <div class="wx-list" v-for="i in list">
        <router-link
          :to="{
            path: '/school/courseDetail',
            query: { course_id: i.course_id },
          }"
          v-if="i.course_id"  
        >
          <div>
            <p class="color-red" :style="'color:' + i.subtitle_color">
              {{ i.subtitle }}
            </p>
            <h4>{{ i.name }}</h4>
            <!-- <p class="color-999">{{ i.start }}-{{ i.end }}</p> -->
            <div class="wx-listImg mt05 clearfix">
              <ul>
                <li class="mui-text-center" v-for="item in i.teacher">
                  <img
                    style="border-radius: 50%; width: 48px; height: 48px"
                    :src="item.avatar.url"
                  />
                  <p class="color-999">{{ item.name }}</p>
                </li>
              </ul>
            </div>
            <div class="wx-listPrice clearfix">
              <p class="mui-pull-left color-999">
                限售{{ i.goods.sales_restriction }}人 | 已报名{{
                  i.goods.sale_amount
                }}人
              </p>
              <p class="mui-pull-right color-red f-18">￥{{ i.order_price }}</p>
              <h3 class="miaosha">{{ i.real_price }}</h3>
            </div>
            <span class="wx-label" v-if="i.vip_free == 1"
              ><img src="@/assets/img/wx-free.png"
            /></span>
            <span class="wx-label" v-if="i.goods.notend_seckill"
              ><img src="@/assets/img/wx-miaosha.png"
            /></span>
            <span class="wx-label" v-if="i.goods.notend_pintuan"
              ><img src="@/assets/img/wx-pintuan.png"
            /></span>
            <span class="wx-label" v-if="i.goods.agreement_class == 1"
              ><img src="@/assets/img/wx-agreement.png" />
            </span>
          </div>
        </router-link>
        <!-- 课程包 -->
        <div v-else>
          <router-link
            :to="{
              path: '/school/courseGroupDetail',
              query: { course_package_id: i.course_package_id },
            }"
          >
            <p class="color-red" :style="'color:' + i.subtitle_color">
              {{ i.subtitle }}
            </p>
            <h4>{{ i.name }}</h4>
            <p class="mt05">
              <img :src="i.first_cover.url" />
            </p>
            <div class="wx-listPrice clearfix">
              <p class="mui-pull-left color-999">
                限售{{ i.goods.sales_restriction }}人 | 已报名{{
                  i.goods.sale_amount
                }}人
              </p>
              <p class="mui-pull-right color-red f-18">￥{{ i.order_price }}</p>
              <h3 class="miaosha">{{ i.real_price }}</h3>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <Bottom :act="2" style="padding-bottom: 50px"></Bottom>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { typelist, list, topList } from "@/api/course";
import Swiper from "../../assets/js/swiper.min.js";
import Bottom from "@/components/bottom";
export default {
  name: "bottom",
  components: {
    Bottom,
  },
  data() {
    return {
      typeList: [],
      list: [],
      tabIndex: 0,
      typeIndex: null,
      selected: 0,
    };
  },
  mounted() {
    let _this = this;
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f6f6f6");
    _this.loadSwiper();
  },
  methods: {
    loadSwiper() {
      this.$nextTick(() => {
        setTimeout(() => {
          console.log("监听");
          var swiper = new Swiper(".inside-ban", {
            pagination: {
              el: ".swiper-pagination",
            },
            autoplay: 2000,
            autoplay: {
              disableOnInteraction: false,
            },
          });
          var galleryTop = new Swiper(".kecheng-listM", {
            spaceBetween: 10,
            allowTouchMove: false,
          });
        }, 1000);
      });
    },
    // 获取分类
    loadType: function () {
      let _this = this;
      typelist({}).then((res) => {
        console.log(res, "分类");
        _this.typeList = res.result;
        _this.typeIndex = _this.typeList[_this.tabIndex];
        console.log(_this.typeIndex, "变化");
        var course_category_id = res.result[0].course_category_id;
        var course_type_id = res.result[0].course_type[0].course_type_id;
        _this.loadList(course_category_id, course_type_id);
        _this.loadSwiper();
      });
    },
    // 加载列表
    loadList(course_category_id, course_type_id) {
      let _this = this;
      topList({
        course_category_id: course_category_id,
        course_type_id: course_type_id,
        page: 1,
        page_size: 100,
      }).then((res) => {
        console.log(res, "lists");
        var data = res.result.items;
        console.log(data, "ddddd");
        //  if (storage.getItem('userInfo') == null) {
        //   var url = _this.GetUrlRelativePath();
        //   storage.setItem("LOGIN_SKIP", url);
        //   _this.$router.push({path:'/login?action=login'})
        // }
        var userInfo = storage.getItem("userInfo");
        //价格计算规则 如果课程有会员价显示会员价和原价（划线价） 不是会员则显示原价不显示划线价  如果该课程没有会员价格则直接显示原价不显示划线价   如果该课程是活动课程 则显示活动价和原价
        data.map(function (n) {
          if (n.course_package_id) {
            //   课程包课程-------------------------------
            if (n.goods.notend_pintuan || n.goods.notend_seckill) {
              if (n.goods.notend_seckill) {
                n.order_price = n.goods.notend_seckill.goods.order_price;
                n.real_price = n.goods.real_price;
              }
              if (n.goods.notend_pintuan) {
                n.order_price = n.goods.notend_pintuan.goods.order_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              if (n.goods.vip_price.length > 0) {
                // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
                if (userInfo) {
                  if (userInfo.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        userInfo.now_user_member.user_member_config_id
                      ) {
                        n.order_price = s.price;
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                  n.real_price = n.goods.real_price;
                } else {
                  n.order_price = n.goods.order_price;
                }
              } else {
                n.order_price = n.goods.order_price;
              }
            }
          } else {
            // 普通课程------------------------------------------------------
            if (n.goods.notend_pintuan || n.goods.notend_seckill) {
              if (n.goods.notend_seckill) {
                n.order_price = n.goods.notend_seckill.goods.order_price;
                n.real_price = n.goods.real_price;
              }
              if (n.goods.notend_pintuan) {
                n.order_price = n.goods.notend_pintuan.goods.order_price;
                n.real_price = n.goods.real_price;
              }
            } else {
              if (n.goods.vip_price.length > 0) {
                // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
                if (userInfo) {
                  if (userInfo.now_user_member) {
                    n.goods.vip_price.map(function (s) {
                      if (
                        s.user_member_config_id ==
                        userInfo.now_user_member.user_member_config_id
                      ) {
                        n.order_price = s.price;
                      }
                    });
                  } else {
                    n.order_price = n.goods.order_price;
                  }
                  n.real_price = n.goods.real_price;
                } else {
                  n.order_price = n.goods.order_price;
                }
              } else {
                n.order_price = n.goods.order_price;
              }
            }
          }
          if (n.order_price == n.real_price) {
            n.real_price = "";
          }
        });
        _this.list = data;
      });
    },
    getTab(index) {
      console.log(index);
      this.tabIndex = index;
      var course_category_id = this.typeList[index].course_category_id;
      var course_type_id = this.typeList[index].course_type[0].course_type_id;
      console.log(this.typeList, "分");
      this.loadList(course_category_id, course_type_id);
      this.typeIndex = this.typeList[this.tabIndex];
    },
    goBanner(jump_id, jump) {
      if (jump == 1) {
        this.$router.push({
          path: "/school/courseDetail",
          query: { course_id: jump_id },
        });
      } else if (jump == 2) {
        this.$router.push({
          path: "/school/courseGroupDetail",
          query: { course_package_id: jump_id },
        });
      } else if (jump == 3) {
        this.$router.push({ path: "/radio/detail", query: { id: jump_id } });
      } else {
        this.$router.push({ path: "/school/detail", query: { id: jump_id } });
      }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
    goLists(course_category_id, course_type_id, name) {
      console.log(123)
      this.$router.push({
        path: "/school/list",
        query: {
          course_category_id: course_category_id,
          course_type_id: course_type_id,
          name: name,
        },
      });
    },
  },
  created() {
    console.log(this.$route.query, "can");
    if (this.$route.query.tabIndex) {
      this.tabIndex = this.$route.query.tabIndex;
    }
    this.loadType();
  },
};
</script>
<style scoped>
@import "../../assets/css/swiper.min.css";
/* .wx-list:last-child{
  padding-bottom: 90px;
} */
.miaosha {
  float: right;
  font-size: 14px;
  padding-top: 0px;
  padding-left: 10px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
}
.daohang {
  bottom: 0;
}
.daohang >>> .swiper-pagination-bullet {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 20px;
  height: 2px;
}
.wx-listPrice p {
  margin: 0 !important;
}
.tabs {
  float: left;
  width: 25%;
  text-align: center;
  height: 44px;
  line-height: 44px;
}
.active {
  font-size: 1rem;
  color: #3561c7;
}
.active span {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.active span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.3rem;
  background: rgba(53, 97, 199, 0.5);
  z-index: -1;
  border-radius: 0.2rem;
}
>>> .van-tabs--card > .van-tabs__wrap {
  height: 80px;
  line-height: 80px;
}
>>> .van-tabs__nav--card {
  height: 80px;
  line-height: 80px;
  border: none !important;
}
.card_icon {
  width: 32px;
  margin: 0 auto;
}
>>> .van-tabs__nav--card .van-tab {
  border: none !important;
}
>>> .van-tabs__nav--card .van-tab.van-tab--active {
  color: #3561c7;
  background-color: transparent;
}
>>> .van-tabs__wrap--scrollable .van-tab {
  width: 25% !important;
}
</style>