<template>
  <div>
    <!-- 底部 -->
    <nav class="mui-bar mui-bar-tab foot">
      <div
        :class="act == 1 ? 'mui-tab-item mui-active' : 'mui-tab-item'"
        @click="goIndex"
      >
        <span class="mui-icon icon-home"></span>
        <span class="mui-tab-label">推荐</span>
      </div>
      <div
        :class="act == 2 ? 'mui-tab-item mui-active' : 'mui-tab-item'"
        @click="goSchool"
      >
        <span class="mui-icon icon-class"></span>
        <span class="mui-tab-label">课程</span>
      </div>
      <div
        :class="act == 3 ? 'mui-tab-item mui-active' : 'mui-tab-item'"
        @click="goRadio"
      >
        <span class="mui-icon icon-radio"></span>
        <span class="mui-tab-label">电台</span>
      </div>
      <div
        :class="act == 5 ? 'mui-tab-item mui-active' : 'mui-tab-item'"
        @click="goStore"
      >
        <span class="mui-icon icon-store"></span>
        <span class="mui-tab-label">商城</span>
      </div>
      <div
        :class="act == 4 ? 'mui-tab-item mui-active' : 'mui-tab-item'"
        @click="goUser"
      >
        <span class="mui-icon icon-member"></span>
        <span class="mui-tab-label">我的</span>
      </div>
    </nav>
  </div>
</template>
<script>
import storage from "@/utils/storage";
export default {
  name: "bottom",
  props: {
    act: Number,
  },
  data() {
    return {};
  },
  methods: {
    goIndex() {
      this.$router.push({ path: "/" });
    },
    goSchool() {
      this.$router.push({ path: "/school" });
    },
    goRadio() {
      this.$router.push({ path: "/radio" });
    },
    goUser() {
      if (storage.getItem("ACCESS_TOKEN")) {
        this.$router.push({ path: "/user" });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    goStore() {
      this.$router.push({ path: "/store" });
    },
  },
  created() {},
};
</script>