<template>
  <div class="bg">
    <img src="@/assets/img/vip_title.png" class="vip_title" alt="" />
    <div class="contanier">
      <div class="vip_qy">
        <div class="vip_qy_title">
          <img src="@/assets/img/vip_title_left.png" alt="" />
          <p>超级会员权益</p>
          <img src="@/assets/img/vip_title_right.png" alt="" />
        </div>
        <div class="vip_icon_group">
          <div v-for="i in interestsList">
            <img :src="i.image.url" alt="" />
            <p>{{ i.name }}</p>
            <!-- <span>3张通用</span> -->
          </div>
        </div>
      </div>
      <div class="kc">
        <div class="kc_title">
          <img src="@/assets/img/vip_dian_left.png" alt="" />
          <p>查看更多课程列表</p>
          <img src="@/assets/img/vip_dian_right.png" alt="" />
        </div>
        <div class="kc_group">
          <div
            class="kc_box"
            @click="openPopup(i.image.url)"
            v-for="i in labelList"
          >
            <div>
              <p>{{ i.name }}</p>
              <span>点击查看</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_jia">￥{{ detail.price }}</div>
        <div class="bottom_btn" @click="goPay">立即购买</div>
      </div>
      <van-popup v-model="show" style="width: 90%">
        <img src="@/assets/img/close.png" class="closes" @click="close" />
        <img :src="img" alt="" style="width: 100%" />
      </van-popup>
      <van-popup class="motalss" v-model="payShow" position="bottom">
        <h4 class="color-666">请选择支付信息，并完成支付</h4>
        <form class="mui-input-group border-top mt05">
          <div class="mui-input-row mui-radio mt05">
            <label class="color-666">
              <img src="@/assets/img/cart-icon2.png" /> 微信支付</label
            >
            <input
              name="radio1"
              type="radio"
              value="2"
              checked
              @change="changePay"
            />
          </div>
          <div class="mui-input-row mui-radio mt05" v-if="!is_weixns">
            <label class="color-666"
              ><img src="@/assets/img/cart-icon3.png" />支付宝支付</label
            >
            <input name="radio1" type="radio" value="1" @change="changePay" />
          </div>
        </form>
        <div class="paybtn" @click="buyMember">立即购买</div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import storage from "@/utils/storage";
import { interests, getUserMembers, label, buyMember } from "@/api/vip";
import { Dialog } from "vant";
import { wapPay, appPay } from "@/api/common";
export default {
  name: "success",
  components: {},
  data() {
    return {
      show: false,
      interestsList: [],
      detail: {},
      labelList: [],
      img: null,
      payShow: false,
      payType: 2,
      bgStyle: false,
      agent_id: null,
      is_weixns: false,
    };
  },
  mounted() {
    let _this = this;
    // this.$nextTick(() => {
    //   // 页面渲染完成后的回调
    //   console.log(this.$refs.bgs.offsetHeight, "高度");
    //   console.log(document.documentElement.clientHeight, "屏幕高度");
    //   var winHeight = document.documentElement.clientHeight;
    //   var bodyHeight = this.$refs.bgs.offsetHeight;
    //   if (winHeight > bodyHeight) {
    //     _this.bgStyle = true;
    //   }
    // });
  },
  methods: {
    openPopup(url) {
      this.show = true;
      this.img = url;
    },
    loadInterests() {
      let _this = this;
      interests({}).then((res) => {
        _this.interestsList = res.result;
      });
    },
    loadMember() {
      let _this = this;
      getUserMembers({ type: 1 }).then((res) => {
        _this.detail = res.result[0];
      });
    },
    loadlabel() {
      let _this = this;
      label({ type: 2, order_by: "asc" }).then((res) => {
        _this.labelList = res.result;
      });
    },
    changePay(e) {
      this.payType = e.target.value;
    },
    goPay() {
      let _this = this;
      if (storage.getItem("ACCESS_TOKEN")) {
        this.payShow = true;
      } else {
        storage.setItem("LOGIN_SKIP", _this.GetUrlRelativePath());
        console.log(_this.is_weixn(), "是否在微信");
        if (_this.is_weixn()) {
          _this.$router.push("/weixinLogin");
        } else {
          _this.$router.push("/register");
        }
      }
    },
    buyMember() {
      let _this = this;
      buyMember({ member_config_id: 7 }).then((res) => {
        console.log(res, "会员下单");
        // if (res.msg == "该用户不是新用户") {
        //   Dialog.confirm({
        //     title: "当前用户不是新用户",
        //     message: "是否为您跳转注册",
        //   })
        //     .then(() => {
        //       storage.removeItem("ACCESS_TOKEN");
        //       storage.setItem("LOGIN_SKIP", _this.GetUrlRelativePath());
        //       if (_this.is_weixn()) {
        //         _this.$router.push("/weixinLogin");
        //       } else {
        //         _this.$router.push("/register");
        //       }
        //     })
        //     .catch(() => {
        //       // on cancel
        //     });
        //   return;
        // }
        if (res.status == 200) {
          _this.order_id = res.result;
          if (_this.is_weixn()) {
            _this.h5PayWechat(res.result);
          } else {
            _this.h5Pay(res.result);
          }
        } else {
          _this.$message.warning(res.msg);
        }
      });
    },
    // H5支付
    h5Pay(id) {
      let _this = this;
      wapPay({
        order_id: id,
        pay_type: _this.payType,
        post_url: "http://wap.srgongkaow.com/payLoading?order_id=" + id,
      }).then((res) => {
        console.log(res, "支付");
        if (_this.payType == 1) {
          document.querySelector("body").innerHTML = res.result;
          document.forms[0].submit();
        } else {
          window.location.replace(res.result);
        }
      });
    },
    // 如果h5在微信浏览器中打开，调用此方法 并提示支付宝支付不可用
    h5PayWechat(id) {
      let _this = this;
      appPay({ order_id: id, pay_type: _this.payType }).then((res) => {
        console.log(res, "微信支付");
        if (res.msg == "请先去授权") {
          var url = _this.GetUrlRelativePath();
          storage.setItem("LOGIN_SKIP", url);
          Dialog.alert({
            message: res.msg,
          }).then(() => {
            router.push("/weixinLogin");
          });
        }
        if (_this.payType == 1) {
          Dialog.alert({
            message:
              "微信浏览器中无法进行支付宝支付，请复制链接至其他浏览器支付",
          }).then(() => {});
        } else {
          _this.wechatSdk = res.result;
          _this.weixinPay(res.result);
        }
      });
    },
    //解决微信内置对象报错
    weixinPay(data) {
      var vm = this;
      console.log(window.location.href,'地址')
        // history.pushState(null, null, "/");
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
      
        vm.onBridgeReady(data);
      }
    },
    // 初始化微信内支付
    onBridgeReady(we) {
      console.log(we);
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: we.appId, //公众号名称，由商户传入
          timeStamp: we.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: we.nonceStr, //随机串
          package: we.packages,
          signType: "MD5", //微信签名方式：
          paySign: we.paySign, //微信签名
          jsApiList: ["chooseWXPay"],
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          vm.$router.push({
            path: "/payLoading",
            query: { order_id: vm.order_id },
          });
        }
      );
    },
    // 判断当前h5是否为在微信浏览器中打开
    is_weixn() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
    close(){
      this.show = false;
    }
  },
  created() {
    console.log(this.$route.query.agent_id);
    if (this.$route.query.agent_id) {
      storage.setItem("agent_id", this.$route.query.agent_id);
    }
    this.is_weixns = this.is_weixn();
    storage.setItem("vipBuy", 1);
    this.loadInterests();
    this.loadMember();
    this.loadlabel();
  },
};
</script>
<style scoped>
.closes{
   position: absolute;
   right: 10px;
   top:10px;
   width: 20px;
   height: 20px;
}
.paybtn {
  height: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  background: #cfab55;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 16px;
}
.mui-radio input[type="radio"],
.mui-checkbox input[type="checkbox"] {
  top: 20px;
}
.mui-input-group .mui-input-row {
  height: auto;
}
.motalss {
  padding: 30px 15px;
}
.mui-input-row label img {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.contanier {
  padding: 0 15px;
}
.bg {
  background: url("/img/vip_bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  zoom: 1;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.bgs {
  background: url("/img/vip_bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  zoom: 1;
  position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}
.vip_title {
  padding: 60px 0 40px 0;
  width: 100%;
}
.vip_qy {
  padding: 14px;
  border: 1px solid #efc4b0;
  border-radius: 20px;
}
.vip_qy_title {
  text-align: center;
}
.vip_qy_title img {
  display: inline-block;
  width: 9%;
}
.vip_qy_title p {
  display: inline-block;
  color: #efc4b0;
  font-size: 18px;
  margin: 0 10px;
  font-weight: bolder;
}
.vip_icon_group {
  overflow: hidden;
}
.vip_icon_group > div {
  float: left;
  width: 25%;
  text-align: center;
  /* margin-bottom: 10px; */
  margin-top: 20px;
}
.vip_icon_group > div > img {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.vip_icon_group > div > p {
  color: #f8d686;
  margin-bottom: 2px;
  font-size: 14px;
}
.vip_icon_group > div > span {
  color: #ebc0ab;
}
.kc {
  margin-top: 20px;
  overflow: hidden;
  padding-bottom: 60px;
}
.kc_title {
  text-align: center;
  margin-bottom: 20px;
}
.kc_title img {
  display: inline-block;
  margin-top: -10px;
}
.kc_title p {
  font-size: 22px;
  color: #e7c8b6;
  font-weight: bolder;
  display: inline-block;
  margin: 0 10px;
}
.kc_group {
  overflow: hidden;
}
.kc_box {
  float: left;
  width: 33.33333333%;
  text-align: center;
  margin-bottom: 20px;
}
.kc_box > div {
  margin: 0 10px;
  background: url("/img/vip_btn.png") no-repeat;
  background-size: 100% 100%;
  padding: 6px 0;
}
.kc_box > div > p {
  color: #f8d686;
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0;
}
.kc_box > div > span {
  font-size: 12px;
  color: #978380;
}
.bottom {
  width: 100%;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #131539;
  overflow: hidden;
}
.bottom_jia {
  float: left;
  width: 60%;
  color: #a7a7af;
  font-size: 20px;
  font-weight: bolder;
  padding-left: 15px;
}
.bottom_btn {
  float: right;
  width: calc(100% - 60% - 15px);
  color: #17183e;
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  border-bottom: 70px solid #cfab55;
  border-left: 30px solid transparent;
  height: 0;
}
</style>