<template>
  <div></div>
</template>
<script>
import { ordrDetail } from "@/api/common";
export default {
  name: "courseDetail",
  data() {
    return {
      goods: null,
      type: null,
      isPin: null,
    };
  },
  methods: {
    loadOrderDetail() {
      let _this = this;
      ordrDetail({ order_id: _this.order_id }).then((res) => {
        console.log(res, "详情");
        var data = res.result;
        if (data.status == 2) {
          _this.$router.push({ path: "/pay/success" });
        } else {
          // _this.$router.push({path:'/pay',query:{goods:JSON.stringify(this.detail.goods),type:1,isPin:false}})
          _this.$router.push({ path: "/pay/fail" });
        }
      });
    },
  },
  created() {
    this.order_id = this.$route.query.order_id;
    // this.goods = this.$route.query.goods;
    // this.type = this.$route.query.type;
    // this.isPin = this.$route.query.isPin;
    this.loadOrderDetail();
  },
};
</script>